<div *ngIf="localRole == 'Executive'" class="top-navigator">
  <div class="long-line">
    <i class="icon feather icon-file-minus"></i>
    <div class="line"></div>
  </div>
  <div class="menu-calling">
    <div class="menu-nameee">
      <span class="major">{{'MENUITEMS.ALL-THE-REPORT' | translate}}</span>
      <span class="ml-2 mr-2">/</span>
      <span>{{'MENUITEMS.MEMBER-REPORT' | translate}}</span>
    </div>
    <div>
      <button (click)="exportexcel(userType <= 0 ? 'Instructor' : 'Learner')" class="download-excel">
        <i class="icon feather icon-download"></i>
        <p>{{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}</p>
      </button>
    </div>
  </div>
</div>
<div *ngIf="localRole=='Admin'" style="display: flex;justify-content: end;padding-bottom: 10px;">
  <button (click)="exportexcel(userType <= 0 ? 'Instructor' : 'Learner')" type="button" class="btn custom-excel-button">
    <i class="icon feather icon-download"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
  </button>
</div>
<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <!-- List Start -->
  <div class="row align-items-center" style="justify-content: space-between;margin: unset; padding-bottom: 15px;">
    <div class="search-barrr">
      <input type="text" [(ngModel)]="searchModel" (ngModelChange)="searchChange($event)"
        placeholder="{{'TEACHER.Q-AND-A.SEARCH' | translate}}...">
      <i class="feather icon-search"></i>
    </div>

    <div class="date-filter" style="border: 1px solid #ccc;border-radius: 8px;">
      <button type="button" [class.active]="activeButton === 'all'" (click)="dateAllNew()"
        class="btn btn-date-filter">{{'ADMIN.contact-us.All' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'yesterday'" (click)="setDateRange('yesterday')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Yesterday' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last7'" (click)="setDateRange('last7')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 7 Days' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last15'" (click)="setDateRange('last15')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 15 Days' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last30'" (click)="setDateRange('last30')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 30 Days' | translate}}</button>
    </div>

    <!-- <div class="date-filter" style="border: 1px solid #ccc;border-radius: 5px; height: 22px;">
      <button type="button" [class.active]="activeButton === 'all'" (click)="tabChange('all')"
        class="btn btn-date-filter">All</button>
      <button type="button" [class.active]="activeButton === 'yesterday'" (click)="tabChange('yesterday')"
        class="btn btn-date-filter">Yesterday</button>
      <button type="button" [class.active]="activeButton === 'last7'" (click)="tabChange('last7')"
        class="btn btn-date-filter">Last 7 Days</button>
      <button type="button" [class.active]="activeButton === 'last15'" (click)="tabChange('last15')"
        class="btn btn-date-filter">Last 15 Days</button>
      <button type="button" [class.active]="activeButton === 'last30'" (click)="tabChange('last30')"
        class="btn btn-date-filter">Last 30 Days</button>
    </div> -->

    <!-- <div class="form-group mb-0">
      <select title="Customer" name="customer" class="form-control select-c" style=" width: 160px;"
        *ngIf="openTeacher == true || openStudent == true" [(ngModel)]="selectUser"
        (change)="clickOpenTeacherAndStudent()">
        <option value="ผู้สอน">{{'EXECUTIVE.INSTRUCTOR' | translate}}</option>
        <option value="ผู้เรียน">{{'EXECUTIVE.LEARNER' | translate}}</option>
      </select>
    </div> -->
    <div class="form-group mb-0">
      <select class="form-control " style=" width: 160px;border-radius: 8px;" [(ngModel)]="userType"
        (ngModelChange)="userTypeChange($event)">
        <option [value]="0">{{'EXECUTIVE.INSTRUCTOR' | translate}}</option>
        <option [value]="1">{{'EXECUTIVE.LEARNER' | translate}}</option>
      </select>
    </div>

    <!-- <div>
      <button *ngIf="openTeacher == true" type="button" class="btn custom-excel-button" data-toggle="tooltip"
        data-placement="bottom" title="{{'EXECUTIVE.DOWNLOAD-EXCEL-INSTRUCTOR' | translate}}"
        (click)="exportexcel('Instructor')"><i
          class="icon feather icon-download f-16 text-c-black"></i>{{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}</button>
      <button *ngIf="openStudent == true" type="button" class="btn custom-excel-button" data-toggle="tooltip"
        data-placement="bottom" title="{{'EXECUTIVE.DOWNLOAD-EXCEL-LEARNER' | translate}}"
        (click)="exportexcel('Learner')"><i
          class="icon feather icon-download f-16 text-c-black"></i>{{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}</button>
    </div> -->
  </div>
  <!-- List end -->

  <!-- List User Start-->
  <div class="row">
    <div class="col-sm-12">

      <div class="table-scroll-x">
        <table class="table-version-z">
          <thead>
            <tr>
              <th class="first">{{'ADMIN.SUBJECT.NO' | translate}}</th>
              <th>{{'EXECUTIVE.NAME' | translate}}</th>
              <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
              <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
              <th class="text-center">{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
              <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
              <th class="last">{{'EXECUTIVE.DATE-CREATE-MEMBER' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="userType <= 0 && !dataLoading1">
              <ng-container *ngIf="users.length">
                <tr *ngFor="let item of users;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                  <td style="text-align: center;">{{item.index}}</td>
                  <td style="text-align: left;width: 250px;">
                    <div class="d-flex align-items-center">
                      <img src="{{urlImage}}{{item.por.image}}" alt="user image" class="img-radius align-top m-r-15"
                        style="width:40px; height: 40px;object-fit: cover;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block align-middle" style="text-align: left;">
                        <p class="m-b-0" style="text-align: left;">{{item.por.fullNameTh}}</p>
                        <!-- <p class="m-b-0">{{item.por.fullNameEn}}</p> -->
                      </div>
                    </div>
                  </td>
                  <td>{{item.por.username}}</td>
                  <td>{{item.por.email}}</td>
                  <td class="text-center">
                    {{item.por.phoneNumber[0]}}******{{item.por.phoneNumber[7]}}{{item.por.phoneNumber[8]}}{{item.por.phoneNumber[9]}}
                  </td>
                  <!-- <td >{{item.phoneNumber}}</td> -->
                  <td>
                    {{item.por.brithOfDate | date:'fullDate'}}

                  </td>
                  <td class="text-center">{{item.por.dateCreate | date: 'short'}}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!users.length && !dataLoading1">
                <tr>
                  <td colspan="7" class="text-center">{{'ADMIN.NO-DATA-SHOWING' | translate}}</td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="userType > 0 && !dataLoading1">
              <ng-container *ngIf="users.length">
                <tr *ngFor="let item of users;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                  <td style="text-align: center;">{{item.index}}</td>
                  <td style=" text-align: left; ">
                    <div class="d-flex align-items-center">
                      <img src="{{urlImage}}{{item.dataStudents.image}}" alt="user image"
                        class="img-radius align-top m-r-15" style="width:40px; height: 40px;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block">
                        <p class="m-b-0" *ngIf="dataLanguage=='th'">{{item.dataStudents.fullNameTh}}</p>
                        <p class="m-b-0" *ngIf="dataLanguage=='en'">{{item.dataStudents.fullNameEn}}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{item.dataStudents.username}}</td>
                  <td>{{item.dataStudents.email}}</td>
                  <td>
                    {{item.dataStudents.phoneNumber[0]}}{{item.dataStudents.phoneNumber[1]}}{{item.dataStudents.phoneNumber[2]}}-{{item.dataStudents.phoneNumber[3]}}{{item.dataStudents.phoneNumber[4]}}{{item.dataStudents.phoneNumber[5]}}-{{item.dataStudents.phoneNumber[6]}}{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}

                  </td>
                  <!-- <td>{{item.dataStudents.phoneNumber}}</td> -->
                  <td><span>{{item.dataStudents.brithOfDate |
                      date:'fullDate'}}</span></td>
                  <td>{{item.dataStudents.dateCreate | date: 'short'}}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!users.length && !dataLoading1">
                <tr>
                  <td colspan="7" class="text-center">{{'ADMIN.NO-DATA-SHOWING' | translate}}</td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="dataLoading1">
              <tr>
                <td colspan="7" class="text-center">
                  <div class="spinner-border text-info" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <!-- Ghost Table for Export Excel Only! -->
        <table class="table-version-z" id="excel-table-{{userType <= 0 ? 'Instructor' : 'Learner'}}"
          style="display: none;">
          <thead>
            <tr>
              <th class="first">{{'ADMIN.SUBJECT.NO' | translate}}</th>
              <th>{{'EXECUTIVE.NAME' | translate}}</th>
              <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
              <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
              <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
              <th class="last">{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="userType <= 0 && !dataLoading1">
              <ng-container *ngIf="usersOrigin.length">
                <tr *ngFor="let item of usersOrigin;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                  <td style="text-align: center;">{{item.index}}</td>
                  <td style="text-align: left;width: 250px;">
                    <div class="d-flex align-items-center">
                      <img src="{{urlImage}}{{item.por.image}}" alt="user image" class="img-radius align-top m-r-15"
                        style="width:40px; height: 40px;object-fit: cover;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block align-middle" style="text-align: left;">
                        <p class="m-b-0" style="text-align: left;">{{item.por.fullNameTh}}</p>
                        <!-- <p class="m-b-0">{{item.por.fullNameEn}}</p> -->
                      </div>
                    </div>
                  </td>
                  <td>{{item.por.username}}</td>
                  <td>{{item.por.email}}</td>
                  <td>
                    {{item.por.phoneNumber[0]}}{{item.por.phoneNumber[1]}}{{item.por.phoneNumber[2]}}-{{item.por.phoneNumber[3]}}{{item.por.phoneNumber[4]}}{{item.por.phoneNumber[5]}}-{{item.por.phoneNumber[6]}}{{item.por.phoneNumber[7]}}{{item.por.phoneNumber[8]}}{{item.por.phoneNumber[9]}}
                  </td>
                  <!-- <td >{{item.phoneNumber}}</td> -->
                  <td>
                    {{item.por.brithOfDate | date:'fullDate'}}

                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!usersOrigin.length && !dataLoading1">
                <tr>
                  <td colspan="6" class="text-center">{{'ADMIN.NO-DATA-SHOWING' | translate}}</td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="userType > 0 && !dataLoading1">
              <ng-container *ngIf="usersOrigin.length">
                <tr *ngFor="let item of usersOrigin;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                  <td style="text-align: center;">{{item.index}}</td>
                  <td style=" text-align: left; ">
                    <div class="d-flex align-items-center">
                      <img src="{{urlImage}}{{item.dataStudents.image}}" alt="user image"
                        class="img-radius align-top m-r-15" style="width:40px; height: 40px;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block">
                        <p class="m-b-0" *ngIf="dataLanguage=='th'">{{item.dataStudents.fullNameTh}}</p>
                        <p class="m-b-0" *ngIf="dataLanguage=='en'">{{item.dataStudents.fullNameEn}}</p>
                      </div>
                    </div>
                  </td>
                  <td>{{item.dataStudents.username}}</td>
                  <td>{{item.dataStudents.email}}</td>
                  <td>
                    {{item.dataStudents.phoneNumber[0]}}{{item.dataStudents.phoneNumber[1]}}{{item.dataStudents.phoneNumber[2]}}-{{item.dataStudents.phoneNumber[3]}}{{item.dataStudents.phoneNumber[4]}}{{item.dataStudents.phoneNumber[5]}}-{{item.dataStudents.phoneNumber[6]}}{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}

                  </td>
                  <!-- <td>{{item.dataStudents.phoneNumber}}</td> -->
                  <td><span>{{item.dataStudents.brithOfDate |
                      date:'fullDate'}}</span></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="!usersOrigin.length && !dataLoading1">
                <tr>
                  <td colspan="6" class="text-center">{{'ADMIN.NO-DATA-SHOWING' | translate}}</td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="dataLoading1">
              <tr>
                <td colspan="6" class="text-center">
                  <div class="spinner-border text-info" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <!-- Ghost Table for Export Excel Only! -->
      </div>
      <div *ngIf="pageCount" class="page-control">
        <p style="padding-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} <span class="ml-1 mr-1">{{page}}</span>
          {{'ADMIN.Q-AND-A.OF' |
          translate}} <span class="ml-1">{{pageCount}}</span></p>
        <div>
          <button (click)="pageChange('prev')" class="prev">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
          <button (click)="pageChange('next')" class="next">{{'ADMIN.Q-AND-A.NEXT' | translate}}</button>
        </div>
      </div>

      <!-- <div style="text-align: start;">
        <label style="margin-right: 5px;"><b>เลือกวันที่กำหนด : </b></label>
        <input type="date" id="start" name="bday" (change)="clearDateEnd()" [(ngModel)]="startDate">
        <label style="margin-right: 5px;margin-left: 5px;"><b>to</b></label>
        <input type="date" id="end" name="bday" [min]="startDate" [value]="endDate" (change)="searchDate($event)">
        <button type="button" class="btn btn-icon btn-warning" style="border-radius: 50%;width: 35px;margin-left: 10px;"
          (click)="clearDateAll()">
          <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i></button>
      </div> -->

      <!-- <script>
        function myFunction() {
          var x = document.getElementById("start").min;
          document.getElementById("end").innerHTML = x;
        }
      </script> -->

      <!-- <app-card [hidHeader]="true" cardClass="user-profile-list"> -->
      <!-- Teacher List Start-->
      <div *ngIf="openTeacher == true" style="display: none;">
        <div class="dt-responsive table-responsive"
          *ngIf="TeacherAndStudent != null && TeacherAndStudent != '' && TeacherAndStudent != undefined ">

          <table class="table table-striped row-border table-hover " *ngIf="!dataLoading1">
            <thead>
              <tr>
                <th>{{'ADMIN.SUBJECT.NO' | translate}}</th>
                <th>{{'EXECUTIVE.NAME' | translate}}</th>
                <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
                <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngIf="filteredUserRegister!=''">
              <tr *ngFor="let item of filteredUserRegister;index as i">
                <td style="text-align: center;">{{i+1}}</td>
                <td style="text-align: left;width: 250px;">
                  <div class="d-inline-block align-middle">
                    <img src="{{urlImage}}{{item.por.image}}" alt="user image" class="img-radius align-top m-r-15"
                      style="width:40px; height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block align-middle" style="text-align: left;">
                      <p class="m-b-0" style="text-align: left;">{{item.por.fullNameTh}}</p>
                      <!-- <p class="m-b-0">{{item.por.fullNameEn}}</p> -->
                    </div>
                  </div>
                </td>
                <td>{{item.por.username}}</td>
                <td>{{item.por.email}}</td>
                <td>
                  {{item.por.phoneNumber[0]}}{{item.por.phoneNumber[1]}}{{item.por.phoneNumber[2]}}-{{item.por.phoneNumber[3]}}{{item.por.phoneNumber[4]}}{{item.por.phoneNumber[5]}}-{{item.por.phoneNumber[6]}}{{item.por.phoneNumber[7]}}{{item.por.phoneNumber[8]}}{{item.por.phoneNumber[9]}}
                </td>
                <!-- <td >{{item.phoneNumber}}</td> -->
                <td>
                  {{item.por.brithOfDate | date:'fullDate'}}

                </td>
              </tr>
            </tbody>
            <div *ngIf="filteredUserRegister==''">
              <p style="color: rgb(175, 175, 175);margin:14px">ไม่พบข้อมูลการค้นหา!</p>
            </div>
          </table>
          <div class="page-control">
            <div>
              <div>
                <span style="margin-right: 15px;">Page {{currentPage}} of {{updatePagination()}}</span>
              </div>
              <button type="button" class="prev" (click)="setPage(currentPage - 1)"
                [disabled]="currentPage === 1">Previous</button>
              <button type="button" class="next" (click)="setPage(currentPage + 1)"
                [disabled]="currentPage === updatePagination()">Next</button>
            </div>
          </div>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
        </div>
      </div>
      <!--Student List Start -->
      <div *ngIf="openStudent == true" style="display: none;">
        <div class="dt-responsive table-responsive"
          *ngIf="TeacherAndStudent != null && TeacherAndStudent != '' && TeacherAndStudent != undefined">
          <table class="table table-striped row-border table-hover" *ngIf="!dataLoading1">
            <thead>
              <tr>
                <th>ลำดับที่</th>
                <th>{{'EXECUTIVE.NAME' | translate}}</th>
                <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
                <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filteredUserStudent;index as i">
                <td style="text-align: center;">{{i+1}}</td>
                <td style=" text-align: left; ">
                  <div class="d-inline-block align-middle">
                    <img src="{{urlImage}}{{item.dataStudents.image}}" alt="user image"
                      class="img-radius align-top m-r-15" style="width:40px; height: 40px;"
                      onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <p class="m-b-0" *ngIf="dataLanguage=='th'">{{item.dataStudents.fullNameTh}}</p>
                      <p class="m-b-0" *ngIf="dataLanguage=='en'">{{item.dataStudents.fullNameEn}}</p>
                    </div>
                  </div>
                </td>
                <td>{{item.dataStudents.username}}</td>
                <td>{{item.dataStudents.email}}</td>
                <td>
                  {{item.dataStudents.phoneNumber[0]}}{{item.dataStudents.phoneNumber[1]}}{{item.dataStudents.phoneNumber[2]}}-{{item.dataStudents.phoneNumber[3]}}{{item.dataStudents.phoneNumber[4]}}{{item.dataStudents.phoneNumber[5]}}-{{item.dataStudents.phoneNumber[6]}}{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}

                </td>
                <!-- <td>{{item.dataStudents.phoneNumber}}</td> -->
                <td><span>{{item.dataStudents.brithOfDate |
                    date:'fullDate'}}</span></td>
              </tr>
              <tr *ngIf="filteredUserStudent==''">
                <h6 style="color: rgb(163, 163, 163);">ไม่มีข้อมูล</h6>
              </tr>
            </tbody>
          </table>

          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
        </div>
      </div>
      <!--Student List End -->
      <!-- </app-card> -->
    </div>
  </div>
  <!-- List User end-->

  <!-- excel Learner -->
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Learner" style="display: none;">
    <thead>
      <tr>
        <th>ลำดับที่</th>
        <th>{{'EXECUTIVE.NAME' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'ADMIN.MEMBER.ID-CARD' | translate}}</th>
        <th>{{'ADMIN.MEMBER.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="TeacherAndStudent != null">
      <tr *ngFor="let item of TeacherAndStudent.dataStudents;index as i">
        <td>{{i+1}}</td>
        <td>
          <h6 class="m-b-0">{{item.dataStudents.fullNameTh}}</h6>
        </td>
        <td>{{item.dataStudents.username}}</td>
        <td>{{item.dataStudents.email}}</td>
        <td>
          {{item.dataStudents.phoneNumber[0]}}{{item.dataStudents.phoneNumber[1]}}{{item.dataStudents.phoneNumber[2]}}-{{item.dataStudents.phoneNumber[3]}}{{item.dataStudents.phoneNumber[4]}}{{item.dataStudents.phoneNumber[5]}}-{{item.dataStudents.phoneNumber[6]}}{{item.dataStudents.phoneNumber[7]}}{{item.dataStudents.phoneNumber[8]}}{{item.dataStudents.phoneNumber[9]}}
        </td>
        <td>{{item.dataStudents.brithOfDate | date:'fullDate'}}</td>
        <td>
          {{item.dataStudents.citizenId[0]}}-{{item.dataStudents.citizenId[1]}}{{item.dataStudents.citizenId[2]}}{{item.dataStudents.citizenId[3]}}{{item.dataStudents.citizenId[4]}}-{{item.dataStudents.citizenId[5]}}{{item.dataStudents.citizenId[6]}}{{item.dataStudents.citizenId[7]}}{{item.dataStudents.citizenId[8]}}{{item.dataStudents.citizenId[9]}}-{{item.dataStudents.citizenId[10]}}{{item.dataStudents.citizenId[11]}}-{{item.dataStudents.citizenId[12]}}
        </td>
        <td>{{item.dataStudents.address}}</td>
      </tr>
    </tbody>
  </table>

  <!-- excel Instructor -->
  <table witdh="100%" border="1" class="table" #table12 id="excel-table-Instructor" style="display: none;">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.NAME' | translate}}</th>
        <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
        <th>{{'ADMIN.MEMBER.ID-CARD' | translate}}</th>
        <th>{{'ADMIN.MEMBER.CURRENT-ADDRESS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="TeacherAndStudent != null">
      <tr *ngFor="let item of TeacherAndStudent.dataTeachers;index as i">
        <td>
          <h6 class="m-b-0">{{item.por.fullNameTh}}</h6>
        </td>
        <td>{{item.por.username}}</td>
        <td>{{item.por.email}}</td>
        <td>
          {{item.por.phoneNumber[0]}}{{item.por.phoneNumber[1]}}{{item.por.phoneNumber[2]}}-{{item.por.phoneNumber[3]}}{{item.por.phoneNumber[4]}}{{item.por.phoneNumber[5]}}-{{item.por.phoneNumber[6]}}{{item.por.phoneNumber[7]}}{{item.por.phoneNumber[8]}}{{item.por.phoneNumber[9]}}
        </td>
        <td>{{item.por.brithOfDate | date:'fullDate'}}</td>
        <td>
          {{item.por.citizenId[0]}}-{{item.por.citizenId[1]}}{{item.por.citizenId[2]}}{{item.por.citizenId[3]}}{{item.por.citizenId[4]}}-{{item.por.citizenId[5]}}{{item.por.citizenId[6]}}{{item.por.citizenId[7]}}{{item.por.citizenId[8]}}{{item.por.citizenId[9]}}-{{item.por.citizenId[10]}}{{item.por.citizenId[11]}}-{{item.por.citizenId[12]}}
        </td>
        <td>{{item.por.address}}</td>
      </tr>
    </tbody>
  </table>
</app-card>