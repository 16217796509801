<div class="container"
  *ngIf="this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div class="m-header">
    <a href="javascript:" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a class="b-brand">
      <img id="main-logo" src="assets/images/logo.png" alt="" class="logo">
      <p style="margin-top: 54px;margin-left: -64px;font-size: 11px;color: white;">Version 3.1 </p>
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb">
    </a>
    <a class="mob-toggler" [ngClass]="{'on' : this.menuClass}" href="javascript:" (click)="toggleMobOption()"><i
        class="feather icon-more-vertical"></i></a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <div class="text-left" style="min-width: 150px;" *ngIf="dataLocal != null && dataLocal.role == 'SuperAdmin'">
      <div class="btn-cust" *ngIf="dataPortalAll && dataPortalAll.length > 0">
        <ng-select [ngClass]="'ng-select'" [options]="dataPortalAll" [(ngModel)]="selectedPortal"
          style="background-color: aliceblue; border-radius: 4px;" *ngIf="dataPortalAll != ''"
          (ngModelChange)="selectProtal($event)">
          <ng-template let-option="dataPortalAll">
            <div class="famfamfam-flags {{dataPortalAll?.value.toLowerCase()}}"></div>
            {{dataPortalAll.label}}
          </ng-template>
        </ng-select>
        <p *ngIf="!dataPortalAll" style="text-align: center;"><b>*
            {{'ADMIN.NO-DATA-SHOWING' | translate}}</b></p>
      </div>
    </div>
    <app-nav-left class="mr-auto"></app-nav-left>
    <div *ngIf="dataLocal != null && dataLocal.role == 'SuperAdmin'">
      <h3 style="color: aliceblue;">
        Portal-{{showPortalSelect}}</h3>
    </div>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>