<!-- รายงานหลักสูตรทั้งหมดของอาจารย์ -->

<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <div class="row" style="justify-content: space-between;margin: unset;padding-bottom: 15px;"
    *ngIf="localRole == 'SuperAdmin' || localRole == 'Admin' || localRole == 'Executive' ">
    <div class="col" style="align-content: center;">
      <h6 style="margin-bottom: unset;">
        {{'EXECUTIVE.SEARCH-BY-NAME-LIST' | translate}}
      </h6>
    </div>
    <div style="display: flex;">
      <div class="select-container" style="margin-right: 15px;">
        <ng-select [ngClass]="'ng-select'" [options]="dataTeacherAll" [(ngModel)]="selectedTeacher"
          *ngIf="selectedTeacher != null && selectedTeacher != '' && selectedTeacher != undefined"
          (ngModelChange)="getDataSubjectTeacher($event)">
          <ng-template let-option="dataStudentAll">
            <div class="famfamfam-flags {{dataTeacherAll?.value.toLowerCase()}}"></div>
            {{dataTeacherAll.label}}
          </ng-template>
        </ng-select>
        <i class="feather icon-user" style="position: absolute; top: 26px;padding-left:12px"></i>
        <p *ngIf="selectedTeacher == null || selectedTeacher == '' || selectedTeacher == undefined"
          style="text-align: center;padding-top: 20px;"><b>*{{'EXECUTIVE.NO-DATA'
            | translate}}</b></p>
      </div>

      <div>
        <button type="button" class="btn custom-excel-button" data-toggle="modal"
          style="float: right;border-radius: 8px;margin-right: 15px;height: 38px;" (click)="exportexcel('ReportTop10')">
          <i class="icon feather icon-download f-14 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' |
          translate}}
        </button>
      </div>

      <div>
        <nav class="nav">
          <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='top'}"
            (click)="onClickTopRatingBase()">
            {{'EXECUTIVE.TOP-10-OF-THE-MOST-POPULAR-COURSES'| translate}}<i
              class="feather icon-corner-right-up text-c-green m-l-10"></i>
          </a>
          <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='worst'}"
            (click)="onClickTopWorst()">
            {{'EXECUTIVE.TOP-10-OF-THE-LEAST-POPULAR-COURSES'| translate}}<i
              class="feather icon-corner-right-down text-c-red m-l-10"></i>
          </a>
        </nav>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <!-- <app-card [hidHeader]="true"> -->
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <div>
            <!-- profile start-->
            <div *ngIf="localRole == 'SuperAdmin' || localRole == 'Admin' || localRole == 'Executive' ">
              <app-card [customHeader]="true" [options]="false" [isCardFooter]="true">
                <div class="app-card-header" *ngIf="detailTeachers != null">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="row align-items-center" style="margin-left: 0;margin-right: 20px;">
                        <div class="col-auto col custom-profile-img">
                          <img class="img-radius img-fluid" style="width: 64px; height: 64px;object-fit: cover;"
                            src="{{urlImage}}{{detailTeachers.por.image}}" alt="User
                    image" onerror="this.src='assets/images/no-img.png'">
                        </div>
                        <div class="col" style="text-align: center;">
                          <p class="mb-1">{{detailTeachers.por.fullNameTh}}
                            ({{detailTeachers.por.fullNameEn}})</p>
                          <p class="mb-0" *ngIf="detailTeachers.por.passStutus == false">{{'EXECUTIVE.TEACHER'
                            | translate}}</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center" *ngIf="detailTeachers != null">
                  <!-- <div class="col">
                <p class="mb-1"><i class="icon feather icon-user f-16 text-c-black" style="padding-right: 10px;"></i>
                </p>
                <p class="mb-0">{{detailTeachers.por.username}}</p>
              </div> -->

                  <!-- <div class="col">
                <p class="mb-1"><i class="icon feather icon-users f-16 text-c-black" style="padding-left: 2px;"></i></p>
                <p class="mb-0" *ngIf="detailTeachers.sex == 'M'">ชาย</p>
                <p class="mb-0" *ngIf="detailTeachers.sex == 'F'">หญิง</p>
              </div> -->

                </div>
                <div class="app-card-footer" *ngIf="detailTeachers != null" style="height: 376px;">
                  <div class="row align-items-center">
                    <div class="col">
                      <p><i class="icon feather icon-credit-card f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.TEACHER-ID'
                        | translate}} :
                        <text>{{detailTeachers.por.tchId}}</text>
                      <p><i class="icon feather icon-credit-card f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.ID-CARD' |
                        translate}}
                        :<text>{{citizen}}</text></p>
                      <p><i class="icon feather icon-mail f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.E-MAIL' |
                        translate}} :
                        <text>{{detailTeachers.por.email}}</text>
                      </p>
                      <p><i class="icon feather icon-phone f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.PHONE-NUMBER' |
                        translate}} :
                        <text> {{formattedPhoneNumber}}</text>
                      </p>
                      <p><i class="icon feather icon-calendar f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.DATE-OF-BIRTH' |
                        translate}} :
                        <text>{{detailTeachers.por.brithOfDate | date:'mediumDate'}}</text>
                      </p>


                      <p><i class="icon feather icon-home f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.CURRENT-ADDRESS'
                        | translate}} :<text> {{address[0]}},{{address[1]}},
                          {{address[2]}},{{address[3]}}</text>
                      </p>
                      <p><i class="icon feather icon-home f-16 text-c-black"
                          style="padding-right: 10px;"></i>{{'EXECUTIVE.ZIP-CODE' |
                        translate}} :<text>{{address[4]}}</text>
                      </p>
                    </div>
                  </div>
                </div>
                <p *ngIf="detailTeachers == null || detailTeachers == '' || detailTeachers == undefined"
                  style="text-align: center;padding-top: 10px;"><text>*{{'EXECUTIVE.NO-DATA'
                    | translate}}</text></p>
              </app-card>
            </div>
            <!-- profile end-->


          </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" style="padding-left: unset;">
          <div class="row">
            <!-- TopBest start-->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="localRole == 'SuperAdmin' || localRole == 'Admin' || localRole == 'Executive'">
              <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
                <!-- tab origin top -->
                <!-- <div>
                  <nav class="nav nav-pills" style="margin: 15px;">
                    <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='top'}"
                      (click)="onClickTopRatingBase()">
                      {{'EXECUTIVE.TOP-10-OF-THE-MOST-POPULAR-COURSES'| translate}}<i
                        class="feather icon-corner-right-up text-c-green m-l-10"></i>
                    </a>
                    <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='worst'}"
                      (click)="onClickTopWorst()">
                      {{'EXECUTIVE.TOP-10-OF-THE-LEAST-POPULAR-COURSES'| translate}}<i
                        class="feather icon-corner-right-down text-c-red m-l-10"></i>
                    </a>
                  </nav>
                </div> -->
                <div class="app-card-body" *ngIf="topRating != null&&currentTabReport=='top'"
                  style="height: 567px; padding-top: 15px;">
                  <perfect-scrollbar style="background-color: white; border-radius:8px;  top: 0px;padding: 0 15px;">
                    <div class="table-scroll-x review-block" *ngIf="!dataLoading2">
                      <table class="table-version-z">
                        <thead>
                          <tr>
                            <th class="first" style="text-align: center;">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                            <th style="text-align: left;">{{'EXECUTIVE.PROFILE.NAME-IN-THAI' | translate}}</th>
                            <th style="text-align: left;">{{'EXECUTIVE.PROFILE.NAME-IN-ENGLISH' | translate}}</th>
                            <th style="text-align: center;">{{'EXECUTIVE.SCORE' | translate}}</th>
                            <th class="last" style="text-align: center;">{{'EXECUTIVE.NUMBER-PROPLE-RATING' |
                              translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let topBest of dataTopBaseTab;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                            <td style="text-align: center;">{{i+1}}</td>
                            <td style="text-align: left;">
                              <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;"
                                ngbTooltip="{{topBest.nameTh}}">
                                {{topBest.nameTh}}</p>
                            </td>
                            <td style="text-align: left;">
                              <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;"
                                ngbTooltip="{{topBest.nameEn}}">{{topBest.nameEn}}</p>
                            </td>
                            <td style="text-align: center;">{{topBest.scroreRating}} </td>
                            <td style="text-align: center;">{{topBest.countRating}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div class="row" *ngFor="let topBest of topRating.topBest;index as i">
                      <div class="col-sm-auto p-r-0">
                        <p style="font-size: 18px;">
                          {{i+1}}</p>
                      </div>
                      <div class="col">
                        <h3 class="m-b-0">{{topBest.nameTh}}</h3>
                        <p class="m-b-0">{{topBest.nameEn}}</p>
                        <div class="row align-items-center">
                          <div class="col">
                            <td>
                              <i
                                [class]="(topBest.scroreRating == 0) ? 'far fa-star text-c-yellow' : (topBest.scroreRating > 0 && topBest.scroreRating < 1) ? 'fas fa-star-half-alt  text-c-yellow' : 'fas fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topBest.scroreRating > 1 && topBest.scroreRating < 2) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 2) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topBest.scroreRating > 2 && topBest.scroreRating < 3) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 3) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topBest.scroreRating > 3 && topBest.scroreRating < 4) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 4) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topBest.scroreRating > 4 && topBest.scroreRating < 5) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 5) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                            </td>
                           
                          </div>
                          <div class="col">
                            <h3 class="m-b-0" style="text-align: end;">
                              {{topBest.scroreRating.toFixed([1])}}&nbsp;&nbsp;({{topBest.countRating}}
                              {{'EXECUTIVE.PERSON' |
                              translate}})</h3>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    </div>
                    <div style="text-align: center;margin: 20px;" *ngIf="dataLoading2">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                      </div>
                    </div>
                  </perfect-scrollbar>

                </div>
                <h6 *ngIf="topRating == null" style="text-align: center;">{{'EXECUTIVE.NO-DATA'
                  | translate}}</h6>

                <div class="app-card-body" *ngIf="topRating != null&&currentTabReport=='worst'"
                  style="height: 567px;padding-top: 15px;">
                  <perfect-scrollbar style="background-color: white; border-radius: 5px; top: 0px;padding: 0 15px;">
                    <div class="table-scroll-x " *ngIf="!dataLoading2">
                      <!-- <div class="row" *ngFor="let topWorst of dataTopWorstTab;index as i"> -->
                      <table class="table-version-z ">
                        <thead>
                          <tr>
                            <th class="first" style="text-align: center;">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                            <th style="text-align: left;">{{'EXECUTIVE.PROFILE.NAME-IN-THAI' | translate}}</th>
                            <th style="text-align: left;">{{'EXECUTIVE.PROFILE.NAME-IN-ENGLISH' | translate}}</th>
                            <th style="text-align: center;">{{'EXECUTIVE.SCORE' | translate}}</th>
                            <th class="last" style="text-align: center;">{{'EXECUTIVE.NUMBER-PROPLE-RATING' |
                              translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let topWorst of dataTopWorstTab;index as i"
                            [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                            <td style="text-align: center;">{{i+1}}</td>
                            <td style="text-align: left;">
                              <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;"
                                ngbTooltip="{{topWorst.nameTh}}">{{topWorst.nameTh}}
                            </td>
                            <td style="text-align: left;">
                              <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 180px;"
                                ngbTooltip="{{topWorst.nameEn}}">{{topWorst.nameEn}}
                            </td>
                            <td style="text-align: center;">{{topWorst.scroreRating}} </td>
                            <td style="text-align: center;">{{topWorst.countRating}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div style="text-align: center;margin: 20px;" *ngIf="dataLoading2">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                      </div>
                    </div>
                  </perfect-scrollbar>

                </div>
                <h6 *ngIf="topRating == null" style="text-align: center;">{{'EXECUTIVE.NO-DATA'
                  | translate}}</h6>


              </app-card>
            </div>

            <!-- TopBest end-->

            <!-- Topworst start -->
            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
                <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;">
                  <p style="font-size: 18px;"><b>{{'EXECUTIVE.TOP-10-OF-THE-LEAST-POPULAR-COURSES'
                      | translate}} </b><i class="fas fa-level-down-alt text-c-red m-l-10"></i></p>
                </div>
                <perfect-scrollbar style="background-color: white; border-radius: 5px; top: 0px;" *ngIf="topRating != null">
                  <div class="review-block" *ngIf="!dataLoading2&&currentTabReport=='worst'">
                    <div class="row" *ngFor="let topWorst of dataTopWorstTab;index as i">
                      <div class="col-sm-auto p-r-0">
                        <p style="font-size: 18px;"><b>{{'EXECUTIVE.TOP' | translate}}
                            {{i+1}}</b></p>
                      </div>
                      <div class="col">
                        <h3 class="m-b-0">{{topWorst.nameTh}}</h3>
                        <p class="m-b-0">{{topWorst.nameEn}}</p>
                        <div class="row align-items-center">
                          <div class="col">
                            <td>
                              <i
                                [class]="(topWorst.scroreRating == 0) ? 'far fa-star text-c-yellow' : (topWorst.scroreRating > 0 && topWorst.scroreRating < 1) ? 'fas fa-star-half-alt  text-c-yellow' : 'fas fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topWorst.scroreRating > 1 && topWorst.scroreRating < 2) ? 'fas fa-star-half-alt  text-c-yellow' : (topWorst.scroreRating >= 2) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topWorst.scroreRating > 2 && topWorst.scroreRating < 3) ? 'fas fa-star-half-alt  text-c-yellow' : (topWorst.scroreRating >= 3) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topWorst.scroreRating > 3 && topWorst.scroreRating < 4) ? 'fas fa-star-half-alt  text-c-yellow' : (topWorst.scroreRating >= 4) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                              <i
                                [class]="(topWorst.scroreRating > 4 && topWorst.scroreRating < 5) ? 'fas fa-star-half-alt  text-c-yellow' : (topWorst.scroreRating >= 5) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                            </td>
                          </div>
                          <div class="col">
                            <h3 class="m-b-0" style="text-align: end;">
                              {{topWorst.scroreRating.toFixed([1])}}&nbsp;&nbsp;({{topWorst.countRating}}
                              {{'EXECUTIVE.PERSON'
                              | translate}})</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="text-align: center;margin: 20px;">
                    <div class="spinner-border text-info" role="status" *ngIf="dataLoading2">
                      <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                    </div>
                  </div>
                </perfect-scrollbar>
                <h3 *ngIf="topRating == null" style="text-align: center;">{{'EXECUTIVE.NO-DATA'
                  | translate}}</h3>
              </app-card>
            </div> -->
            <!-- Topworst end-->
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <app-card [hidHeader]="true">
                <app-card [hidHeader]="true" cardClass="user-profile-list">
                  <div
                    style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;justify-content: space-between;display: flex;">
                    <h3>{{'EXECUTIVE.FIND-REPORT-EXAMSUBJECT' | translate}} </h3>
                    <ng-select [ngClass]="'ng-select'" [options]="dataSubjectAll" [(ngModel)]="selectedSubject"
                      *ngIf="selectedSubject != null && selectedSubject != '' && selectedSubject != undefined"
                      (ngModelChange)="getFindReportExamSubject($event)" style="width: 500px;margin-right: 10px;">
                      <ng-template let-option="dataSubjectAll">
                        {{dataSubjectAll.label}}
                      </ng-template>
                    </ng-select>
                  </div>
                  <div>
                    <nav class="nav nav-pills" style="margin: 15px;">
                      <a class="nav-link topbutton" type="button"
                        [ngClass]="{'active':currentTabstudy==='studying'}">รายงานข้อมูลผู้เรียนสถานะ "กำลังเรียน"
                      </a>
                      <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='worst'}">
                        {{'EXECUTIVE.COURSE-COMPLETION-REPORT' | translate}}
                      </a>
                      <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='worst'}">
                        รายงานสถิติจำนวนผู้เข้าเรียนและพิมพ์ใบรับรอง
                      </a>
                    </nav>
                  </div>
                  <div id="chart" *ngIf="currentTabstudy=='studying'">
                    <app-apex-chart *ngIf="chartReportStudying" chartID="analytics-customer6-chart"
                      [chartConfig]="chartReportStudying"></app-apex-chart>
                  </div>
                </app-card>
              </app-card>
            </div> -->
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="localRole == 'Teacher'">
          <app-card cardTitle="Testimonial" [hidHeader]="true" cardClass="testimonial-card table-card">
            <!-- 1465 -->
            <div>
              <nav class="nav nav-pills" style="margin: 15px;">
                <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='top'}"
                  (click)="onClickTopRatingBase()">
                  {{'EXECUTIVE.TOP-10-OF-THE-MOST-POPULAR-COURSES'| translate}}<i
                    class="fas fa-level-up-alt text-c-green m-l-10"></i>
                </a>
                <a class="nav-link topbutton" type="button" [ngClass]="{'active':currentTabReport==='worst'}"
                  (click)="onClickTopWorst()">
                  {{'EXECUTIVE.TOP-10-OF-THE-LEAST-POPULAR-COURSES'| translate}}<i
                    class="fas fa-level-down-alt text-c-red m-l-10"></i>
                </a>
              </nav>
            </div>
            <!-- 1465 -->
            <!-- ส่วนของpoppular -->
            <perfect-scrollbar style="background-color: white; border-radius:8px;  top: 0px;padding: 0 15px;"
              *ngIf="topRating != null && currentTabReport=='top'">
              <div class="table-scroll-x" *ngIf="!dataLoading2&&currentTabReport=='top'" style="overflow: auto;">
                <table class="table-version-z">
                  <thead>
                    <tr>
                      <th class="first">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                      <th>{{'EXECUTIVE.PROFILE.NAME-IN-THAI' | translate}}</th>
                      <th>{{'EXECUTIVE.PROFILE.NAME-IN-ENGLISH' | translate}}</th>
                      <th style="text-align: center;">{{'EXECUTIVE.SCORE' | translate}}</th>
                      <th style="text-align: center;">{{'EXECUTIVE.NUMBER-PROPLE-RATING' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let topBest of dataTopBaseTab;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                      <td style="text-align: center;">
                        {{i+1}}
                      </td>
                      <td style="text-align: left;">
                        <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                          ngbTooltip="{{topBest.nameTh}}">{{topBest.nameTh}}</p>
                      </td>
                      <td style="text-align: left;">
                        <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                          ngbTooltip="{{topBest.nameEn}}">{{topBest.nameEn}}</p>
                      </td>
                      <td style="text-align: center;">{{topBest.scroreRating}} </td>
                      <td style="text-align: center;">{{topBest.countRating}}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row" *ngFor="let topBest of topRating.topBest;index as i">
                  <div class="col-sm-auto p-r-0">
                    <p style="font-size: 18px;">
                      {{i+1}}</p>
                  </div>
                  <div class="col">
                    <h3 class="m-b-0">{{topBest.nameTh}}</h3>
                    <p class="m-b-0">{{topBest.nameEn}}</p>
                    <div class="row align-items-center">
                      <div class="col">
                        <td>
                          <i
                            [class]="(topBest.scroreRating == 0) ? 'far fa-star text-c-yellow' : (topBest.scroreRating > 0 && topBest.scroreRating < 1) ? 'fas fa-star-half-alt  text-c-yellow' : 'fas fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(topBest.scroreRating > 1 && topBest.scroreRating < 2) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 2) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(topBest.scroreRating > 2 && topBest.scroreRating < 3) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 3) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(topBest.scroreRating > 3 && topBest.scroreRating < 4) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 4) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                          <i
                            [class]="(topBest.scroreRating > 4 && topBest.scroreRating < 5) ? 'fas fa-star-half-alt  text-c-yellow' : (topBest.scroreRating >= 5) ? 'fas fa-star  text-c-yellow' : 'far fa-star  text-c-yellow'"></i>
                        </td>
                       
                      </div>
                      <div class="col">
                        <h3 class="m-b-0" style="text-align: end;">
                          {{topBest.scroreRating.toFixed([1])}}&nbsp;&nbsp;({{topBest.countRating}}
                          {{'EXECUTIVE.PERSON' |
                          translate}})</h3>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div style="text-align: center;margin: 20px;">
                <div class="spinner-border text-info" role="status" *ngIf="dataLoading2">
                  <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                </div>
              </div>
            </perfect-scrollbar>
            <h6 *ngIf="topRating == null" style="text-align: center;">{{'EXECUTIVE.NO-DATA'
              | translate}}</h6>

            <!-- ส่วนของ least -->
            <perfect-scrollbar style="background-color: white; border-radius: 5px; top: 0px;padding: 0 15px;"
              *ngIf="topRating != null&&currentTabReport=='worst'">
              <div class="table-scroll-x" *ngIf="!dataLoading2&&currentTabReport=='worst'">
                <!-- <div class="row" *ngFor="let topWorst of dataTopWorstTab;index as i"> -->
                <table class="table-version-z">
                  <thead>
                    <tr>
                      <th class="first">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                      <th>{{'EXECUTIVE.PROFILE.NAME-IN-THAI' | translate}}</th>
                      <th>{{'EXECUTIVE.PROFILE.NAME-IN-ENGLISH' | translate}}</th>
                      <th style="text-align: center;">{{'EXECUTIVE.SCORE' | translate}}</th>
                      <th class="last">{{'EXECUTIVE.NUMBER-PROPLE-RATING' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let topWorst of dataTopWorstTab;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                      <td style="text-align: center;">{{i+1}}</td>
                      <td style="text-align: left;">
                        <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                          ngbTooltip="{{topWorst.nameTh}}">{{topWorst.nameTh}}</p>
                      </td>
                      <td style="text-align: left;">
                        <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                          ngbTooltip="{{topWorst.nameEn}}">{{topWorst.nameEn}}</p>
                      </td>
                      <td style="text-align: center;">{{topWorst.scroreRating}} </td>
                      <td style="text-align: center;">{{topWorst.countRating}}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <table class="table-version-z">
                    <thead>
                      <tr>
                        <th class="first">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                        <th>{{'EXECUTIVE.PROFILE.NAME-IN-THAI' | translate}}</th>
                        <th>{{'EXECUTIVE.PROFILE.NAME-IN-ENGLISH' | translate}}</th>
                        <th style="text-align: center;">{{'EXECUTIVE.SCORE' | translate}}</th>
                        <th style="text-align: center;">{{'EXECUTIVE.NUMBER-PROPLE-RATING' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let topBest of dataTopBaseTab;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                        <td>
                          <p style="text-align: center;">{{i+1}}</p>
                        </td>
                        <td style="text-align: left;">
                          <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                            ngbTooltip="{{topBest.nameTh}}">{{topBest.nameTh}}</p>
                        </td>
                        <td style="text-align: left;">
                          <p style="margin-bottom: unset;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 280px;"
                            ngbTooltip="{{topBest.nameEn}}">{{topBest.nameEn}}</p>
                        </td>
                        <td style="text-align: center;">{{topBest.scroreRating}} </td>
                        <td style="text-align: center;">{{topBest.countRating}}</td>
                      </tr>
                    </tbody>
                  </table> -->
              </div>
              <div style="text-align: center;margin: 20px;">
                <div class="spinner-border text-info" role="status" *ngIf="dataLoading2">
                  <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                </div>
              </div>
            </perfect-scrollbar>
            <h6 *ngIf="topRating == null" style="text-align: center;">{{'EXECUTIVE.NO-DATA'
              | translate}}</h6>


          </app-card>
        </div>
      </div>
      <!-- </app-card> -->
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <!-- <app-card [hidHeader]="true"> -->
      <div class="row">
        <!--corse start-->
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <app-card cardClass="table-card" [hidHeader]="true">
            <div class="row"
              style="padding-top: 20px;padding-left: 10px;justify-content: space-between;display: flex;margin: unset;">
              <h6>{{'EXECUTIVE.ALL-COURSES' | translate}}</h6>
              <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
                title="{{'EXECUTIVE.DOWNLOAD-ALL-COURSES' | translate}}" style="margin-bottom: 10px;margin-right: 5px;"
                (click)="exportexcel('Subject-All')">
                <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL'
                | translate}}
              </button>
            </div>
            <div *ngIf="reportSubjectAll != null" style="height: 416px;">
              <perfect-scrollbar style="background-color: white; border-radius: 5px; height: 439px; top: 0px;">
                <div *ngFor="let Subject of reportSubjectAll;index as i">
                  <p class="custom-text">
                    <span>
                      {{i+1}}. {{Subject.nameTh}}
                    </span>
                  </p>
                </div>

                <!-- <div class="table-responsive">
                  <table class="table table-hover m-b-0 without-header" *ngIf="!dataLoading1">
                    <tbody>
                      <tr *ngFor="let Subject of reportSubjectAll" (click)="OpenList(Subject)">
                        <td>
                          <div class="d-inline-block align-middle">
                            <div class="d-inline-block">
                              <h3 class="m-b-0" style="text-overflow: ellipsis; overflow: hidden;width: 150px;">
                                {{Subject.nameTh}}</h3>
                              <p class="m-b-0" style="text-overflow: ellipsis; overflow: hidden;width: 150px;">
                                {{Subject.nameEn}}</p>
                            </div>
                          </div>
                        </td>
                        <td class="text-right">
                          <h3 class="m-b-0">{{Subject.countStudentLearn}} {{'EXECUTIVE.GENERAL-PERSON'
                            | translate}}<i class="fas fa-users text-c-green m-l-10"></i>
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
              </perfect-scrollbar>
            </div>
            <div style="text-align: center;margin: 20px;">
              <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
              </div>
            </div>
            <p *ngIf="!dataLoading1 && reportSubjectAll == null " style="text-align: center;padding-top: 10px;">
              <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
            </p>
          </app-card>
        </div>
        <!-- corse end -->

        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" style="padding-left: unset;">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <app-card [hidHeader]="true">
                <div class="row"
                  style="padding-bottom: 10px;margin: unset;justify-content: space-between;display: flex;">
                  <h6 style="margin-bottom: unset;align-self: center;">{{'EXECUTIVE.FIND-REPORT-EXAMSUBJECT' |
                    translate}}</h6>
                  <ng-select [ngClass]="'ng-select'" [options]="dataSubjectAll" [(ngModel)]="selectedSubject"
                    *ngIf="selectedSubject != null && selectedSubject != '' && selectedSubject != undefined"
                    (ngModelChange)="getFindReportExamSubject($event)" style="width: 400px;margin-right: 10px;">
                    <ng-template let-option="dataSubjectAll">
                      {{dataSubjectAll.label}}
                    </ng-template>
                  </ng-select>
                </div>


                <!-- <div>
                  <div style="margin: 15px;display: flex;">
                    <div class="nav-link topbutton text-over" type="button" (click)="onclickChartStudying()"
                      [ngClass]="{'active':currentTabstudy==='studying'}"
                      ngbTooltip=" {{'EXECUTIVE.STUDY-STATUS-STUDYING'| translate}}">
                      {{'EXECUTIVE.STUDY-STATUS-STUDYING'| translate}}
                    </div>
                    <div class="nav-link topbutton text-over" type="button" (click)=" onclickChartGraduation()"
                      [ngClass]="{'active':currentTabstudy==='graduation'}"
                      ngbTooltip=" {{'EXECUTIVE.COURSE-COMPLETION-REPORT'| translate}}">
                      {{'EXECUTIVE.COURSE-COMPLETION-REPORT'| translate}}
                    </div>
                    <div class="nav-link topbutton text-over" type="button" (click)="onclickChartCertificate()"
                      [ngClass]="{'active':currentTabstudy==='certificate'}"
                      ngbTooltip="{{'EXECUTIVE.STATISTICS-REPORT-CERTIFICATE-PRINTING'| translate}}">
                      {{'EXECUTIVE.STATISTICS-REPORT-CERTIFICATE-PRINTING'| translate}}
                    </div>
                  </div>
                </div> -->
                <!-- <div id="chart" *ngIf="currentTabstudy=='studying'">
                  <app-apex-chart *ngIf="chartReportStudying" chartID="analytics-customer6-chart"
                    [chartConfig]="chartReportStudying"></app-apex-chart>
                </div>
                <div id="chart" *ngIf="currentTabstudy=='graduation'">
                  <app-apex-chart *ngIf="chartgraduation" chartID="analytics-customer6-chart"
                    [chartConfig]="chartgraduation"></app-apex-chart>
                </div>
                <div id="chart" *ngIf="currentTabstudy=='certificate'">
                  <app-apex-chart *ngIf="chartCertificate" chartID="analytics-customer6-chart"
                    [chartConfig]="chartCertificate"></app-apex-chart>
                </div> -->
                <!-- (click)="studyingCount != null ? openDataStudyingList(studyingCount) : ''" -->
                <div class="card" style="cursor: pointer;" (click)="openDataStudyingList(studyingCount)">
                  <div class="card-body" style="padding: 10px;">
                    <h6 class="card-title bg-gradient">{{'EXECUTIVE.STUDY-STATUS-STUDYING'| translate}}</h6>
                    <div class="row" style="justify-content: space-around;">
                      <h6 class="card-text">รายงานข้อมูลผู้เรียนสถานะ "กำลังเรียน"
                        <h4 class="text-center">{{studyingCount? studyingCount.length:0}}
                        </h4>
                      </h6>
                      <!-- <h6 class="card-text">
                        <h4 class="text-center">{{studyingCount.length}}</h4>
                      </h6> -->
                    </div>
                  </div>
                </div>
                <div class="card" style="cursor: pointer;"
                  (click)="openFindReportStudentGraduateTheCourse(studentGraduate)">
                  <div class="card-body" style="padding: 10px;">
                    <h6 class="card-title bg-gradient">{{'EXECUTIVE.COURSE-COMPLETION-REPORT'| translate}}</h6>
                    <div class="row" style="justify-content: space-around;">
                      <h6 class="card-text">{{'EXECUTIVE.ALL-LEARNERS' |
                        translate}}
                        <h4 class="text-center">{{studentGraduate? studentGraduate.countListAll:0}}</h4>
                      </h6>
                      <h6 class="card-text">{{'EXECUTIVE.STUDENT-COMPLETE-COURSE' |
                        translate}}
                        <h4 class="text-center">{{studentGraduate? studentGraduate.countListSudentGraduate:0}}</h4>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="card" style="cursor: pointer;margin-bottom: 8px"
                  (click)="openFindReportCountStudentAndCertificate(studentAndCertificate)">
                  <div class="card-body" style="padding: 10px;">
                    <h6 class="card-title bg-gradient">{{'EXECUTIVE.STATISTICS-REPORT-CERTIFICATE-PRINTING'|
                      translate}}
                    </h6>
                    <div class="row" style="justify-content: space-around;">
                      <h6 class="card-text ">{{'EXECUTIVE.ALL-LEARNERS' |
                        translate}}
                        <h4 class="text-center">{{studentAndCertificate? studentAndCertificate.countListAll:0}}</h4>
                      </h6>
                      <h6 class="card-text ">{{'EXECUTIVE.CERTIFICATE-HOLDER' |
                        translate}}
                        <h4 class="text-center">{{studentAndCertificate? studentAndCertificate.countListCertificate:0}}
                        </h4>
                      </h6>
                    </div>

                  </div>
                </div>
              </app-card>
            </div>
          </div>


        </div>
      </div>
      <!-- </app-card> -->
    </div>




    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <app-card [hidHeader]="true">
        <!-- <div
            style="padding-top: 20px;padding-bottom: 10px;padding-left: 10px;justify-content: space-between;display: flex;">
            <h3>{{'EXECUTIVE.FIND-REPORT-EXAMSUBJECT' | translate}}</h3>
            <ng-select [ngClass]="'ng-select'" [options]="dataSubjectAll" [(ngModel)]="selectedSubject"
              *ngIf="selectedSubject != null && selectedSubject != '' && selectedSubject != undefined"
              (ngModelChange)="getFindReportExamSubject($event)" style="width: 500px;margin-right: 10px;">
              <ng-template let-option="dataSubjectAll">
                {{dataSubjectAll.label}}
              </ng-template>
            </ng-select>
          </div> -->
        <!-- <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12" style="margin-top: 10px;">
              <app-card [hidHeader]="true" cardClass="proj-t-card"
                (click)="studyingCount != null ? openDataStudyingList(studyingCount) : ''">
                <h3 style="margin-bottom: 10px;text-align: center;">รายงานข้อมูลผู้เรียน</h3>
                <h3 style="margin-bottom: 20px;text-align: center;">สถานะ "กำลังเรียน"</h3>
                <div class="row align-items-center text-center">
                  <div class="col">
                    <h3 class="m-b-0" *ngIf="studyingCount != null">ทั้งหมด {{studyingCount.length}} คน</h3>
                    <h3 class="m-b-0" *ngIf="studyingCount == null">ทั้งหมด 0 คน</h3>
                  </div>
                </div>
               
              </app-card>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="margin-top: 10px;">
              <app-card [hidHeader]="true" cardClass="proj-t-card"
                (click)="openFindReportStudentGraduateTheCourse(studentGraduate)">
                <h3 style="margin-bottom: 20px;text-align: center;">{{'EXECUTIVE.COURSE-COMPLETION-REPORT'
                  | translate}}</h3>
                <div class="row align-items-center">
                  <div class="col p-l-0">
                    <h3 class="m-b-5">{{'EXECUTIVE.ALL-LEARNERS' | translate}}</h3>
                  </div>
                  <div class="col text-right">
                    <h3 class="m-b-5">{{'EXECUTIVE.STUDENT-COMPLETE-COURSE' |
                      translate}}</h3>
                  </div>
                </div>
                <div class="row align-items-center text-center" *ngIf="studentGraduate != null">
                  <div class="col">
                    <h3 class="m-b-0">{{studentGraduate.countListAll}}</h3>
                  </div>
                  <div class="col"><i class="fas fa-exchange-alt text-c-yellow f-18"></i></div>
                  <div class="col">
                    <h3 class="m-b-0">{{studentGraduate.countListSudentGraduate}}</h3>
                  </div>
                </div>
                
              </app-card>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12" style="margin-top: 10px;">
              <app-card [hidHeader]="true" cardClass="proj-t-card"
                (click)="openFindReportCountStudentAndCertificate(studentAndCertificate)">
                <h3 style="margin-bottom: 20px;text-align: center;">{{'EXECUTIVE.STATISTICS-REPORT-CERTIFICATE-PRINTING'
                  | translate}}</h3>
                <div class="row align-items-center">
                  <div class="col p-l-0">
                    <h3 class="m-b-5">{{'EXECUTIVE.ALL-LEARNERS' | translate}}</h3>
                  </div>
                  <div class="col text-right">
                    <h3 class="m-b-5">{{'EXECUTIVE.CERTIFICATE-HOLDER' |
                      translate}}</h3>
                  </div>
                </div>
                <div class="row align-items-center text-center" *ngIf="studentAndCertificate != null">
                  <div class="col">
                    <h3 class="m-b-0">{{studentAndCertificate.countListAll}}</h3>
                  </div>
                  <div class="col"><i class="fas fa-exchange-alt text-c-yellow f-18"></i></div>
                  <div class="col">
                    <h3 class="m-b-0">{{studentAndCertificate.countListCertificate}}</h3>
                  </div>
                </div>
               
              </app-card>
            </div>
          </div> -->
        <div style="justify-content: space-between;display: flex;margin-bottom: 15px;">
          <div class="search-barrr">
            <input type="text" [(ngModel)]="searchTermPretest" (ngModelChange)="filterPretest()"
              placeholder="Search...">
            <i class="feather icon-search"></i>
          </div>
          <!-- <div class="searchterm" style="margin-top: 12px;" *ngIf="dataExamSubject.posttest">
            <input type="text" [(ngModel)]="searchTermPosttest" (ngModelChange)="filterPosttest()"
              placeholder="Search..." class="form-control custom-search">
          </div> -->
          <div *ngIf="dataExamSubject != null && dataSubjectAll != null">
            <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
              title="Tooltip on bottom" style="margin-bottom: 10px;margin-right: 5px;float: right;"
              *ngIf="dataExamSubject.posttest != null && dataExamSubject.posttest != '' && dataExamSubject.posttest != undefined "
              (click)="exportexcel('Post-test')">
              <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL'
              | translate}} {{'EXECUTIVE.POST-TEST' | translate}}
            </button>
            <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
              title="Tooltip on bottom" style="margin-bottom: 10px;margin-right: 5px;float: right;"
              *ngIf="dataExamSubject.pretest != null && dataExamSubject.pretest != '' && dataExamSubject.pretest != undefined "
              (click)="exportexcel('Pre-test')">
              <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL'
              | translate}} {{'EXECUTIVE.PRE-TEST' | translate}}
            </button>
          </div>
          <!-- <div>
              <label style="margin-right: 5px;"><b>Search : </b></label>
              <input type="date" id="start" name="bday" (change)="clearDateEnd()" [(ngModel)]="startDate">
              <label style="margin-right: 5px;margin-left: 5px;"><b>to</b></label>
              <input type="date" id="end" name="bday" [min]="startDate" [value]="endDate" (change)="searchDate($event)">
              <button type="button" class="btn btn-icon btn-warning"
                style="border-radius: 50%;width: 35px;margin-left: 10px;" (click)="clearDateAll()">
                <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i></button>
            </div>

            <script>
              function myFunction() {
                var x = document.getElementById("start").min;
                document.getElementById("end").innerHTML = x;
              }
            </script> -->
        </div>
        <ngb-tabset type="pills" [destroyOnHide]="false" *ngIf="dataSubjectAll != null">
          <!-- สอบก่อนเรียน -->
          <ngb-tab class="mb-3">
            <ng-template ngbTabTitle class="btn-tab">{{'EXECUTIVE.PRE-TEST' | translate}}</ng-template>
            <ng-template ngbTabContent *ngIf="dataExamSubject != null">
              <div class="table-scroll-x" style="margin-top: 20px;"
                *ngIf="dataExamSubject.pretest != null && dataExamSubject.pretest != '' && dataExamSubject.pretest != undefined ">


                <table class="table-version-z" *ngIf="!dataLoading1">
                  <thead>
                    <tr>
                      <th class="first">{{'EXECUTIVE.NAME' | translate}}</th>
                      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                      <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                      <th>{{'TEACHER.CHECK-FOR-WRITING-EXAM.DATE'|translate}}</th>
                      <th class="last">{{'EXECUTIVE.MORE-INFORMATION' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filteredPretest;index as i" (click)="openFindReportExamSubject(item)"
                      [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                      <td style="text-align: left;">
                        <div class="d-inline-block align-middle">
                          <img src="{{urlImage}}{{item.dataStudent.image}}" alt="user image" class="img-radius m-r-15"
                            style="width:40px; height: 40px;object-fit: cover;"
                            onerror="this.src='assets/images/no-img.png'">
                          <div class="d-inline-block">
                            <p class="m-b-0">{{dataLanguage=='th'?
                              item.dataStudent.fullNameTh:item.dataStudent.fullNameEn}}</p>

                          </div>
                        </div>
                      </td>
                      <td>{{item.dataStudent.email}}</td>
                      <td>
                        *******{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
                      </td>
                      <!-- <td >{{item.phoneNumber}}</td> -->
                      <td>{{item.dataStudent.brithOfDate | date:'fullDate'}}</td>
                      <td>{{item.dataPreTest.date | date:'fullDate'}}</td>
                      <td style="text-align: center;">
                        <span type="button" style="margin-right: 5px;" (click)="openFindReportExamSubject(item)"><i
                            class="feather icon-eye text-i-view"></i></span>
                        <!-- <div class="overlay-edit">
                          <button type="button" class="btn btn-icon btn-secondary"
                            >
                            </button>
                         <button type="button" class="btn btn-icon btn-danger"
                              (click)="deleteCourserseCatgory(i,item.categoryId)">
                              <i class="feather icon-trash-2"></i>
                            </button>
                        </div> -->
                      </td>
                    </tr>
                    <div *ngIf="filteredPretest==''">
                      <p style="color: rgb(175, 175, 175);margin:14px">ไม่พบข้อมูลการค้นหา!</p>
                    </div>
                  </tbody>
                </table>
                <div style="text-align: center;margin: 20px;">
                  <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                    <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                  </div>
                </div>
              </div>
              <div class="page-control" style="margin-top: 12px;">
                <p style="padding-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} {{currentPagePretest}}
                  {{'ADMIN.Q-AND-A.OF'
                  |translate}} {{updatePaginationPretest()}}</p>
                <div>
                  <button type="button" class="prev" style="margin-right: 5px;"
                    (click)="setPage(currentPagePretest - 1)"
                    [disabled]="currentPagePretest === 1">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
                  <button type="button" class="next" (click)="setPagePretest(currentPagePretest + 1)"
                    [disabled]="currentPagePretest === updatePaginationPretest()">{{'ADMIN.Q-AND-A.NEXT' |
                    translate}}</button>
                </div>
                <!-- <div>
                  <button type="button" class="btn btn-light" style="margin-right: 5px;"
                    (click)="setPagePretest(currentPagePretest - 1)"
                    [disabled]="currentPagePretest === 1">Previous</button>
                  <button type="button" class="btn btn-light" (click)="setPagePretest(currentPagePretest + 1)"
                    [disabled]="currentPagePretest === updatePaginationPretest()">Next</button>
                </div> -->

              </div>
              <p *ngIf="dataExamSubject.pretest == '' && !dataLoading1" style="text-align: center;padding-top: 10px;">
                <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
              </p>
            </ng-template>
          </ngb-tab>
          <!-- สอบหลังเรียน -->
          <ngb-tab class="mb-3">
            <ng-template ngbTabTitle>{{'EXECUTIVE.POST-TEST' | translate}}</ng-template>
            <ng-template ngbTabContent *ngIf="dataExamSubject != null">
              <div class="table-scroll-x"
                *ngIf="dataExamSubject.posttest != null && dataExamSubject.posttest != '' && dataExamSubject.posttest != undefined ">
                <!-- <div class="searchterm" style="margin-top: 12px;">
                  <input type="text" [(ngModel)]="searchTermPosttest" (ngModelChange)="filterPosttest()"
                    placeholder="Search..." class="form-control custom-search">
                </div> -->
                <table class="table-version-z" *ngIf="!dataLoading1">
                  <thead>
                    <tr>
                      <th class="first">{{'EXECUTIVE.NAME' | translate}}</th>
                      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
                      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                      <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                      <th class="last" style="text-align: center;">{{'EXECUTIVE.MORE-INFORMATION' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of filteredPosttest;index as i" (click)="openFindReportExamSubject(item)"
                      [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                      <td>
                        <div class="d-inline-block align-middle">
                          <img src="{{urlImage}}{{item.dataStudent.image}}" alt="user image" class="img-radius m-r-15"
                            style="width:40px; height: 40px;object-fit: cover;"
                            onerror="this.src='assets/images/no-img.png'">
                          <div class="d-inline-block">
                            <p class="m-b-0">{{dataLanguage=='th' ?
                              item.dataStudent.fullNameTh:item.dataStudent.fullNameEn}}</p>

                          </div>
                        </div>
                      </td>
                      <td>{{item.dataStudent.email}}</td>
                      <td>
                        *******{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
                      </td>
                      <!-- <td >{{item.phoneNumber}}</td> -->
                      <td>{{item.dataStudent.brithOfDate | date:'fullDate'}}</td>
                      <td style="text-align: center;">
                        <span type="button" (click)="openFindReportExamSubject(item)" style="margin-right: 5px;"> <i
                            class="feather icon-eye text-i-view"></i></span>
                        <!-- <div class="overlay-edit">
                          <button type="button" class="btn btn-icon btn-secondary"
                            (click)="openFindReportExamSubject(item)">
                           </button>
                          <button type="button" class="btn btn-icon btn-danger"
                              (click)="deleteCourserseCatgory(i,item.categoryId)">
                              <i class="feather icon-trash-2"></i>
                            </button>
                        </div> -->
                      </td>
                    </tr>
                    <div *ngIf="filteredPosttest==''">
                      <p style="color: rgb(175, 175, 175);margin:14px">ไม่พบข้อมูลการค้นหา!</p>
                    </div>
                  </tbody>
                </table>

                <div style="text-align: center;margin-bottom: 10px;">
                  <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
                    <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                  </div>
                </div>
              </div>
              <div class="page-control">
                <div>
                  <p style="padding-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} {{currentPagePosttest}}
                    {{'ADMIN.Q-AND-A.OF'
                    |translate}} {{updatePaginationPosttest()}} </p>
                </div>
                <div>
                  <button type="button" class="prev" (click)="setPagePosttest(currentPagePosttest - 1)"
                    [disabled]="currentPagePosttest === 1">{{'ADMIN.Q-AND-A.PREVIOUS' |
                    translate}}</button>
                  <button type="button" class="next" (click)="setPagePosttest(currentPagePosttest + 1)"
                    [disabled]="currentPagePosttest === updatePaginationPosttest()">{{'ADMIN.Q-AND-A.NEXT' |
                    translate}}</button>
                </div>
              </div>
              <p *ngIf="dataExamSubject.posttest == '' && !dataLoading1" style="text-align: center;padding-top: 10px;">
                <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
              </p>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
        <p *ngIf="dataSubjectAll == null && !dataLoading1" style="text-align: center;padding-top: 10px;">
          <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
        </p>
      </app-card>
    </div>

    <!-- corse mamber start -->
    <app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'EXECUTIVE.LEARNER-NAME-LIST' | translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="myLargeModalLabel.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
          title="Tooltip on bottom" style="margin-bottom: 10px;margin-right: 5px;float: right;"
          (click)="exportexcel('List-of-students')">
          <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' |
          translate}}
        </button>
        <perfect-scrollbar [perfectScrollbar]
          style="background-color: white; border-radius: 5px; height: 439px; top: 0px;"
          *ngIf="Liststd != null && Liststd != '' && Liststd != undefined">
          <div class="table-responsive">
            <table class="table table-xl" id="excel-table-List-of-students">
              <thead>
                <tr>
                  <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
                  <th>{{'EXECUTIVE.NAME' | translate}}</th>
                  <!-- <th>{{'EXECUTIVE.USERNAME' | translate}}</th> -->
                  <!-- <th>{{'EXECUTIVE.E-MAIL' | translate}}</th> -->
                  <th>{{'EXECUTIVE.EXAM-START-DATE' | translate}}</th>
                  <th>{{'EXECUTIVE.NUMBER-OF-HOURS' | translate}}</th>
                  <th>{{'EXECUTIVE.PASSED-TEST-DATE' | translate}}</th>
                </tr>
              </thead>
              <tbody *ngIf="Liststd != null">
                <tr *ngFor="let item of Liststd; index as i">
                  <td>{{i+1}}</td>
                  <td>{{item.dataStudents.dataStudent.fullNameTh}}</td>
                  <!-- <td>{{item.dataStudents.dataStudent.username}}</td> -->
                  <!-- <td>{{item.dataStudents.dataStudent.email}}</td> -->

                  <td *ngIf="item.datePreTest != null" style="color: green;">{{item.datePreTest}}</td>
                  <td *ngIf="item.datePreTest == null" style="color: red;">{{'EXECUTIVE.HAVEN-NOT-EXAM'
                    | translate}}</td>

                  <td>{{item.house}} {{'ADMIN.COURSE.HOUR' | translate}}</td>

                  <td *ngIf="item.datePostTest != null" style="color: green;">{{item.datePostTest}}</td>
                  <td *ngIf="item.datePostTest == null" style="color: red;">{{'EXECUTIVE.FAILED-THE-TEST'
                    | translate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </perfect-scrollbar>
      </div>
    </app-ui-modal>
    <!-- corse mamber end -->

    <!-- รายงานผลการสอบก่อนเรียน และ หลังเรียน -->
    <app-ui-modal #myFindReportExamSubject [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'EXECUTIVE.EXAM-INFORMATION' | translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="myFindReportExamSubject.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <div class="col-lg-12">
          <div style="display: flex;justify-content: space-between;">

            <!-- <div class="row"> -->
            <div class="col-sm-6" *ngIf="dataStudent != undefined" style="display: flex;">
              <div style="text-align: left;">
                <img class="img-radius img-fluid" style="width: 70px;height: 70px;object-fit: cover;"
                  src="{{urlImage}}{{dataStudent.image}}" alt="User
                  image" onerror="this.src='assets/images/no-img.png'">
              </div>
              <div style="display: inline-block;margin-left: 25px;">
                <p>{{dataStudent.fullNameTh}}</p>
                <p>{{dataStudent.fullNameEn}}</p>
                <p>{{'EXECUTIVE.E-MAIL' | translate}}: {{dataStudent.email}}</p>
                <p>{{'EXECUTIVE.PHONE-NUMBER' | translate}}:
                  {{dataStudent.phoneNumber}}</p>
              </div>
            </div>

            <div class="col-sm-6" *ngIf="dataPreTest != undefined">
              <div *ngIf="dataPreTest.dataSubject.tchId != undefined" style="display: flex;">
                <div style="text-align: left;">
                  <img class="img-radius img-fluid" style="width: 70px;height: 70px;object-fit: cover;"
                    src="{{urlImage}}{{dataPreTest.dataSubject.tchId.image}}" alt="User
                    image" onerror="this.src='assets/images/no-img.png'">
                </div>
                <div style="display: inline-block;margin-left: 25px;">
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}} :
                    {{dataPreTest.dataSubject.tchId.fullNameTh}}
                  </p>
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}} :
                    {{dataPreTest.dataSubject.tchId.fullNameEn}}</p>
                  <p>{{'EXECUTIVE.E-MAIL' | translate}}:
                    {{dataPreTest.dataSubject.tchId.ermail}}</p>
                  <p>{{'EXECUTIVE.PHONE-NUMBER' | translate}}:
                    {{dataPreTest.dataSubject.tchId.phoneNumber}}</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6" *ngIf="dataPostTestt != undefined">
              <div *ngIf="dataPostTestt.length != 0" style="display: flex;">
                <!-- <div style="display: flex;justify-content: space-between;"> -->
                <div style="text-align: left;" *ngIf="dataPostTestt != undefined">
                  <img class="img-radius img-fluid" style="width: 70px;height: 70px;object-fit: cover;"
                    src="{{urlImage}}{{dataPostTestt[0].dataSubject.dataTeacher.image}}" alt="User
                      image" onerror="this.src='assets/images/no-img.png'">
                </div>
                <div style="display: inline-block;margin-left: 25px;">
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}}:
                    {{dataPostTestt[0].dataSubject.dataTeacher.fullNameTh}}
                  </p>
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}:
                    {{dataPostTestt[0].dataSubject.dataTeacher.fullNameEn}}</p>

                  <p>{{'EXECUTIVE.E-MAIL' | translate}}:
                    {{dataPostTestt[0].dataSubject.dataTeacher.ermail}}</p>
                  <p>{{'EXECUTIVE.PHONE-NUMBER' | translate}}:
                    {{dataPostTestt[0].dataSubject.dataTeacher.phoneNumber}}</p>
                </div>
                <!-- </div> -->
              </div>
            </div>
            <!-- </div> -->

          </div>
          <hr />
          <table *ngIf="dataPreTest != undefined">
            <tr>
              <td class="td">
                <p>{{'EXECUTIVE.SUBJECT' | translate}}: </p>
              </td>
              <td>{{dataPreTest.dataSubject.nameTh}}
                ({{dataPreTest.dataSubject.nameEn}})</td>
            </tr>
            <tr>
              <td class="td">
                <p>{{'EXECUTIVE.EXAM-CONDITION' | translate}}: </p>
              </td>
              <td>{{dataPreTest.conditionExam}}</td>
            </tr>
            <tr>
              <td class="td">
                <p>{{'EXECUTIVE.EXAM-DATE' | translate}} : </p>
              </td>
              <td>{{dataPreTest.date| date:'fullDate'}}</td>
            </tr>
            <tr>
              <td class="td">
                <p>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}}: </p>
              </td>
              <td>{{dataPreTest.numberOfExam}}</td>
            </tr>
            <tr>
              <td class="td">
                <p>{{'EXECUTIVE.SCORE' | translate}}: </p>
              </td>
              <td>{{dataPreTest.score}}</td>
            </tr>
            <tr>
              <td class="td">
                <p>{{'TEACHER.STATUS' | translate}}: </p>
              </td>
              <td *ngIf="dataPreTest.statusResult == 'successful'">
                <p class="badge badge-pill badge-light-true">{{'EXECUTIVE.PASSED-THE-TEST' |
                  translate}}</p>
              </td>
              <td *ngIf="dataPreTest.statusResult == 'failed'">
                <p class="badge badge-pill badge-light-false">{{'EXECUTIVE.FAILED-THE-TEST' |
                  translate}}</p>
              </td>
            </tr>
          </table>
          <div *ngIf="dataPostTestt != undefined">
            <div *ngIf="dataPostTestt.length != 0">
              <div style="display: flex;justify-content: space-between;">
                <p>{{'EXECUTIVE.SUBJECT' | translate}}:
                  {{dataPostTestt[0].dataSubject.nameTh}}
                  ({{dataPostTestt[0].dataSubject.nameEn}})</p>
              </div>
              <div class="table-scroll-x">
                <table class="table-version-z" *ngIf="!loading">
                  <thead style="text-align: center;">
                    <tr>
                      <th class="first"> {{'ADMIN.COURSE.NO' | translate}} </th>
                      <th>{{'EXECUTIVE.EXAM-CONDITION' | translate}}</th>
                      <th style="text-align: left;"> {{'EXECUTIVE.EXAM-DATE' | translate}}</th>
                      <th> {{'ADMIN.COURSE.AMOUNT-OF-EXAMINATION' | translate}} </th>
                      <th> {{'TEACHER.TIME-FOR-EXAM' | translate}} </th>
                      <th>{{'EXECUTIVE.SCORE' | translate}} </th>
                      <th class="last"> {{'TEACHER.STATUS' | translate}} </th>
                    </tr>
                  </thead>
                  <tbody style=" text-align: center;">
                    <tr *ngFor="let item of dataPostTestt;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                      <td>{{i+1}}</td>
                      <td>{{item.conditionExam}}</td>
                      <td style="text-align: left;">{{item.date|date:'fullDate'}}</td>
                      <td>{{item.numberOfExam}}</td>
                      <td *ngIf="item.timeExam == '30'">30 {{'ADMIN.COURSE.MINUTE' |
                        translate}}</td>
                      <td *ngIf="item.timeExam == '60'">1 {{'ADMIN.COURSE.HOUR' |
                        translate}}</td>
                      <td *ngIf="item.timeExam == '90'">1 {{'ADMIN.COURSE.HOUR' |
                        translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
                      <td *ngIf="item.timeExam == '120'">2 {{'ADMIN.COURSE.HOUR' |
                        translate}}</td>
                      <td *ngIf="item.timeExam == '150'">2 {{'ADMIN.COURSE.HOUR' |
                        translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
                      <td *ngIf="item.timeExam == '180'">3 {{'ADMIN.COURSE.HOUR' |
                        translate}}</td>
                      <td *ngIf="item.timeExam == '210'">3 {{'ADMIN.COURSE.HOUR' |
                        translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
                      <td *ngIf="item.timeExam == '240'">4 {{'ADMIN.COURSE.HOUR' |
                        translate}}</td>
                      <td *ngIf="item.timeExam == '270'">4 {{'ADMIN.COURSE.HOUR' |
                        translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
                      <td>{{item.score}}</td>
                      <td *ngIf="item.statusResult == 'successful'">
                        <p class="badge badge-pill badge-light-true">{{'EXECUTIVE.PASSED-THE-TEST' |
                          translate}}</p>
                      </td>
                      <td *ngIf="item.statusResult == 'waitAnswer'">
                        <p class="badge badge-pill badge-light-warning">{{'EXECUTIVE.WAITING-FOR-VERIFY'
                          | translate}}</p>
                      </td>
                      <td *ngIf="item.statusResult == 'failed'">
                        <p class="badge badge-pill badge-light-false">{{'EXECUTIVE.FAILED-THE-TEST' |
                          translate}}</p>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>

              <!-- <table datatable class="table" *ngIf="loading">
                <thead>
                  <tr>
                    <td>
                      <p>{{'ADMIN.COURSE.NO' | translate}} </p>
                    </td>
                    <td>
                      <p>{{'EXECUTIVE.EXAM-CONDITION' | translate}} </p>
                    </td>
                    <td>
                      <p>{{'EXECUTIVE.EXAM-DATE' | translate}} </p>
                    </td>
                    <td>
                      <p>{{'ADMIN.COURSE.AMOUNT-OF-EXAMINATION' | translate}} </p>
                    </td>
                    <td>
                      <p>{{'TEACHER.TIME-FOR-EXAM' | translate}} ({{'ADMIN.COURSE.MINUTE' | translate}})</p>
                    </td>
                    <td>
                      <p>{{'EXECUTIVE.SCORE' | translate}} </p>
                    </td>
                    <td>
                      <p>{{'TEACHER.STATUS' | translate}} </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-info" role="status">
                          <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>
          <!-- <div *ngIf="dataPostTestt != undefined">
            <div *ngIf="dataPostTestt.dataSubject!= undefined">
              <p>{{'EXECUTIVE.INSTRUCTOR' | translate}}: {{dataPostTestt.dataSubject.dataTeacher.fullNameTh}}</p>
            </div>
          </div> -->

        </div>
      </div>
    </app-ui-modal>

    <!-- รายงานผู้เรียนจบตามรายหลักสูตร -->
    <app-ui-modal #myStudentGraduateTheCourse [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <p class="modal-title">{{'EXECUTIVE.ALL-LEARNERS' | translate}}</p>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="myStudentGraduateTheCourse.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <!-- <app-card [hidHeader]="true" cardClass="user-profile-list" style="margin-top: -30px;"> -->
        <div style="display: flex;justify-content: end;">
          <button type="button" class="btn custom-excel-button" data-toggle="modal"
            (click)="exportexcel('Find-Report-Student-Graduate-Course')" style="margin-bottom: 10px;margin-right: 5px;">
            <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' |
            translate}}
          </button>
        </div>
        <div class="table-scroll-x"
          *ngIf="dataGraduateTheCourse != null && dataGraduateTheCourse != '' && dataGraduateTheCourse != undefined ">
          <table class="table-version-z" *ngIf="!dataLoading1">
            <thead>
              <tr>
                <th class="first">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.NAME' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.E-MAIL' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                <th class="last text-center">{{'EXECUTIVE.MORE-INFORMATION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataGraduateTheCourse.dataDetail;index as i"
                [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                <td class="text-center">{{i+1}}</td>
                <td>
                  <div class="d-inline-block">
                    <img src="{{urlImage}}{{item.dataStudent.image}}" alt="user image" class="img-radius m-r-15"
                      style="width:40px; height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <p class="m-b-0">{{dataLanguage=='th'?item.dataStudent.fullNameTh:item.dataStudent.fullNameEn}}
                      </p>

                    </div>
                  </div>
                </td>
                <td>{{item.dataStudent.email}}</td>
                <td *ngIf="item.dataStudent.phoneNumber != ''">
                  *******{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
                <td *ngIf="item.dataStudent.phoneNumber == ''"> - </td>
                <!-- <td >{{item.phoneNumber}}</td> -->
                <td>{{item.dataStudent.brithOfDate | date:'fullDate'}}</td>
                <td class="text-center" *ngIf="item.dataGraduate == null"><span
                    class="badge badge-pill badge-light-false text-center">{{'EXECUTIVE.NO-INFORMATION' |
                    translate}}</span></td>
                <td *ngIf="item.dataGraduate != null" class="text-center">
                  <span type="button" (click)="openViewGraduateTheCourse(item.dataGraduate)">
                    <i class="icon feather icon-eye f-16 text-i-view"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
        </div>
        <!-- </app-card> -->
      </div>
      <!-- </app-card> -->
    </app-ui-modal>

    <!-- รายงานสถิติจำนวนผู้เข้าเรียนและพิมพ์ใบรับรอง-->
    <app-ui-modal #myCountStudentAndCertificate [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'EXECUTIVE.ENROLLMENT-DATA-CERTIFICATE' |
          translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="myCountStudentAndCertificate.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <!-- <app-card [hidHeader]="true" cardClass="user-profile-list" style="margin-top: -30px;"> -->
        <div style="display: flex;justify-content: end;">

          <button type="button" class="btn custom-excel-button" data-toggle="modal"
            (click)="exportexcel('Find-Report-Count-Student-And-Certificate')"
            style="margin-bottom: 15px;margin-right: 5px;">
            <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL'
            | translate}}
          </button>
        </div>
        <div class="table-scroll-x"
          *ngIf="dataStudentAndCertificate != null && dataStudentAndCertificate != '' && dataStudentAndCertificate != undefined ">
          <table class="table-version-z" *ngIf="!dataLoading1">
            <thead>
              <tr>
                <th class="first">{{'EXECUTIVE.NAME' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.NAME' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.E-MAIL' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                <th class="last">{{'ADMIN.CERTIFICATES.CERTIFICATES' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataStudentAndCertificate.dataDetail;index as i"
                [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                <td class="text-center">{{i+1}}</td>
                <td>
                  <div class="d-inline-block align-middle">
                    <img src="{{urlImage}}{{item.dataStudent.image}}" alt="user image" class="img-radius m-r-15"
                      style="width:40px; height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <p class="m-b-0">{{dataLanguage=='th'? item.dataStudent.fullNameTh:item.dataStudent.fullNameEn}}
                      </p>

                    </div>
                  </div>
                </td>
                <td>{{item.dataStudent.email}}</td>
                <td *ngIf="item.dataStudent.phoneNumber != ''">
                  *******{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
                <td *ngIf="item.dataStudent.phoneNumber == ''"> - </td>
                <!-- <td >{{item.phoneNumber}}</td> -->
                <td>{{item.dataStudent.brithOfDate | date:'fullDate'}}</td>
                <td class="text-center" *ngIf="item.dataCertificateStudent == null"><span
                    class="badge badge-pill badge-light-false">{{'EXECUTIVE.NO-INFORMATION'
                    | translate}}</span></td>
                <td class="text-center" *ngIf="item.dataCertificateStudent != null">
                  <span type="button" (click)="openViewCertificate(item)">
                    <i class="icon feather icon-eye f-16 text-i-view"></i></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
        </div>
        <!-- <p *ngIf="dataExamSubject.pretest == '' && !dataLoading1" style="text-align: center;padding-top: 10px;">
            <b>*{{'EXECUTIVE.NO-DATA' | translate}}</b>
          </p> -->
        <!-- </app-card> -->
      </div>
    </app-ui-modal>

    <!-- รายงานข้อมูลผู้เรียน สถานะ  "กำลังเรียน"  ตามรายหลักสูตร -->
    <app-ui-modal #myCountStudentStudying [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'EXECUTIVE.ALL-LEARNERS' | translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="myCountStudentStudying.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <div class="row">
          <div class="search-barrr">
            <input type="text" [(ngModel)]="searchModel1" (ngModelChange)="searchChange1($event)"
              placeholder="{{'TEACHER.Q-AND-A.SEARCH' | translate}}...">
            <i class="feather icon-search"></i>
          </div>
          <!-- <app-card [hidHeader]="true" cardClass="user-profile-list" style="margin-top: -30px;"> -->
          <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
            title="{{'EXECUTIVE.DOWNLOAD-ALL-COURSES' | translate}}" style="margin-bottom: 10px;margin-right: 5px;"
            (click)="exportexcel('Student-Information-Report')">
            <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL'
            | translate}} รายงานข้อมูลผู้เรียน
          </button>
        </div>

        <div class="table-scroll-x" style="height: 500px;overflow-x: hidden;"
          *ngIf="studyingList != null && studyingList != '' && studyingList != undefined ">
          <table class="table-version-z" *ngIf="!dataLoading1">
            <thead>
              <tr>
                <th class="first">{{'EXECUTIVE.ARRANGE' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.NAME' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.E-MAIL' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
                <th class="text-center">{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
                <th class="last">{{'EXECUTIVE.MORE-INFORMATION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of studyingList;index as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                <td class="text-center">{{i+1}}</td>
                <td>
                  <div class="d-inline-block">
                    <img src="{{urlImage}}{{item.dataStudent.image}}" alt="user image" class="img-radius  m-r-15"
                      style="width:40px; height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <p class="m-b-0">{{dataLanguage=="th"? item.dataStudent.fullNameTh:item.dataStudent.fullNameEn}}
                      </p>
                      <!-- <p class="m-b-0">{{item.dataStudent.fullNameEn}}</p> -->
                    </div>
                  </div>
                </td>
                <td>{{item.dataStudent.email}}</td>
                <td class="text-center" *ngIf="item.dataStudent.phoneNumber != ''">
                  *******{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
                <td *ngIf="item.dataStudent.phoneNumber == ''"> - </td>
                <!-- <td >{{item.phoneNumber}}</td> -->
                <td class="text-center">{{item.dataStudent.brithOfDate | date:'fullDate'}}</td>
                <td class="text-center">
                  <span type="button" (click)="openViewStudyingList(item)">
                    <i class="icon feather icon-eye f-16 text-i-view"></i></span>

                </td>
              </tr>
            </tbody>
          </table>
          <div style="text-align: center;margin: 20px;">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoading1">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
        </div>
        <div *ngIf="pageCount1" class="page-control">

          <p style="margin-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} <span class="ml-1 mr-1">{{page1}}</span>
            {{'ADMIN.Q-AND-A.OF' |
            translate}} <span class="ml-1">{{pageCount1}}</span></p>
          <div>
            <button (click)="pageChange1('prev')" class="prev">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
            <button (click)="pageChange1('next')" class="next">{{'ADMIN.Q-AND-A.NEXT' | translate}}</button>
          </div>
        </div>
        <div *ngIf="studyingList == null || studyingList == '' || studyingList == undefined">
          No Data
        </div>

        <!-- </app-card> -->
      </div>
      <!-- </app-card> -->
    </app-ui-modal>

    <!--ใบประกาศนียบัตร (sub modal)-->
    <app-ui-modal #printCertificate [hideFooter]="true" [dialogClass]="'modal-xl'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'ADMIN.CERTIFICATES.CERTIFICATES' |
          translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="closeCer()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <div style="text-align: center;margin: 20px;" *ngIf="dataLoadingCer == true">
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div>
        <div *ngIf="dataCertificate != undefined && showCertificate == true && !dataLoadingCer">
          <div style="text-align: center;" *ngIf="dataLoadingClickCer == true">
            <div class="spinner-border text-info" role="status" *ngIf="dataLoadingClickCer == true">
              <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
            </div>
          </div>
          <div style="text-align: center;cursor: pointer;margin-bottom: 15px;" *ngIf="dataLoadingClickCer == false">
            <!-- <p (click)="generateImage('')" class="text-dowload"> -->
            <!-- <p (click)="openPDF(dataCertificate.background)" class="text-dowload"> -->
            <button type="button" (click)="generateImage()" class="btn text-dowload">
              <i class="feather icon-download"></i>
              {{'EXECUTIVE.CLICK-DOWNLOAD' | translate}}
            </button>
          </div>
          <div class="col-lg-12 col-md-12" class="certificate"
            *ngIf="dataCertificate != undefined && showCertificate == true">
            <div id="image-section"
              [class]="dataCertificate.background == 1 ? 'certificate bg-bg1' : dataCertificate.background == 2 ? 'certificate bg-bg2' : 'certificate bg-bg3'">
              <img class="img-fluid img" *ngIf="imgLogo != ''" src="{{imgLogo}}" alt="Profile-user">
              <p class="textCer">{{dataCertificate.certTitle}}</p>
              <p><br></p>
              <p class="textTest">มอบไว้เพื่อแสดงว่า</p>
              <p><br></p>
              <p class="textName">{{dataItemByTd.dataStudent.fullNameTh}}</p>
              <div class="texthr"></div>
              <p class="textNext">ผ่านการอบรมหลักสูตร {{dataItemByTd.dataCertificateStudent.dataSubject.nameTh}}</p>
              <!-- <p class="textNext">{{dataCertificate.certDiscription2}}</p> -->
              <p class="textDate" *ngIf="dateCreate != undefined">{{'ADMIN.CERTIFICATES.GIVEN-ON-THE'
                | translate}}
                {{dateCreate}}</p>
              <img class="img-fluid textSignature" *ngIf="imgsignature != ''" src="{{imgsignature}}" alt="Profile-user">
              <p class="textSign">({{dataCertificate.nameExecutive}})</p>
              <p class="textPosition">{{dataCertificate.executiveRank}}</p>
            </div>
          </div>
          <!-- <div id="image-section" class="certificate" style="background-image:
          url('assets/images/certificate_bg/Certificate_1.jpg');text-align:
          center;">
            <img class="img-fluid img" *ngIf="imgLogo != ''" src="{{imgLogo}}" alt="Profile-user">
            <p class="textCer">{{dataCertificate.certTitle}}</p>
            <p class="badge badge-pill courses" style="background-color: rgb(94, 219, 167); border-radius: 20px;">
              {{'ADMIN.CERTIFICATES.COURSES' | translate}}
              {{dataItemByTd.dataCertificateStudent.dataSubject.nameTh}}</p>
            <p class="textTest">{{dataCertificate.certDiscription1}}</p>
            <p class="textName">{{dataItemByTd.dataStudent.fullNameTh}}</p>
            <p class="textNext">{{dataCertificate.certDiscription1}}</p>
            <p class="textDate" *ngIf="dateCreate != undefined">{{'ADMIN.CERTIFICATES.GIVEN-ON-THE'
              | translate}}
              {{dateCreate}}</p>
            <img class="img-fluid textSignature" *ngIf="imgsignature != ''" src="{{imgsignature}}" alt="Profile-user">
            <p class="textSign">({{dataCertificate.nameExecutive}})</p>
            <p class="textPosition">{{dataCertificate.executiveRank}}</p>
          </div> -->
        </div>
      </div>
    </app-ui-modal>
    <!-- ข้อมูลผู้เรียนจบตามรายหลักสูตร (sub modal)-->
    <app-ui-modal #showGraduateTheCourse [hideFooter]="true" [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h6 class="modal-title">{{'EXECUTIVE.INFORMATION-STUDENT-COMPLETE-COURSE'
          | translate}}</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="closeGraduateTheCourse()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body" *ngIf="dataGraduateTheCourseById != null">
        <div *ngIf="dataGraduateTheCourseById.dataSubject != 0">
          <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <app-card [hidHeader]="true" blockClass="user-radial-card">
                <div style="display: table;margin: auto;">
                  <img src="{{urlImage}}{{dataGraduateTheCourseById.dataSubject.dataTeacher.image}}" alt="user image"
                    class="img-radius align-top m-r-15" style="width: 60px;height: 60px;object-fit: cover;"
                    onerror="this.src='assets/images/no-img.png'">
                </div>
                <div style="display: table;margin: auto;margin-top: 15px;">
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.dataTeacher.fullNameTh}}</p>
                  <p>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.dataTeacher.fullNameEn}}</p>
                  <p>{{'EXECUTIVE.E-MAIL' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.dataTeacher.ermail}}</p>
                  <p>{{'EXECUTIVE.PHONE-NUMBER' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.dataTeacher.phoneNumber}}</p>
                </div>
              </app-card>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <app-card [hidHeader]="true" blockClass="user-radial-card">
                <div style="text-align: left;height: 159px;">
                  <p>{{'EXECUTIVE.COURSE-CODE' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.subjectId}}</p>
                  <p>{{'EXECUTIVE.SUBJECT' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.nameTh}}</p>
                  <p>{{'EXECUTIVE.SUBJECT' | translate}} :
                    {{dataGraduateTheCourseById.dataSubject.nameEn}}</p>
                  <p>{{'ADMIN.COURSE.TIME-FOR-EXAM' | translate}} :
                    {{dataGraduateTheCourseById.timeExam}} {{'ADMIN.COURSE.MINUTE'
                    | translate}}</p>
                  <p>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}} :
                    {{dataGraduateTheCourseById.numberOfExam}} {{'EXECUTIVE.ITEM'
                    | translate}}</p>
                  <p>{{'EXECUTIVE.EXAM-CONDITION' | translate}} :
                    {{dataGraduateTheCourseById.conditionExam}} {{'EXECUTIVE.ITEM'
                    | translate}}</p>
                  <p>{{'EXECUTIVE.COURES-COMEPLETE-DATE' | translate}} :
                    {{dataGraduateTheCourseById.date | date:'short'}}</p>
                </div>
              </app-card>
            </div>
          </div>
          <hr style="margin-top: -5px;">
          <div style="display: flex;justify-content: space-between;margin-top: 10px;">
            <span>
              <p>{{'EXECUTIVE.SCORE' | translate}} :
                {{dataGraduateTheCourseById.score}} {{'EXECUTIVE.SCORE' |
                translate}}</p>
            </span>
            <span>
              <p>{{'TEACHER.STATUS' | translate}} : <span class="badge badge-pill badge-light-true"
                  style="margin-left: 10px;">{{'ADMIN.Q-AND-A.COMPLETE'
                  | translate}}</span></p>
            </span>
          </div>
        </div>
      </div>
    </app-ui-modal>

    <app-ui-modal #myStudentStudyingList [hideFooter]="true" [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h6 class="modal-title">ข้อมูลผู้เรียน สถานะ "กำลังเรียนตามรายหลักสูตร</h6>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="closeStudyingList()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body" *ngIf="dataStudyingById != null">
        <div *ngIf="dataStudyingById != 0">
          <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <app-card [hidHeader]="true" blockClass="user-radial-card">
                <div style="display: table;margin: auto;">
                  <img src="{{urlImage}}{{dataStudyingById.dataStudent.image}}" alt="user image"
                    class="img-radius align-top m-r-15" style="width: 60px;height: 60px;object-fit: cover;"
                    onerror="this.src='assets/images/no-img.png'">
                </div>
                <div style="display: table;margin: auto;margin-top: 15px;">
                  <p>ชื่อ (ภาษาไทย) :
                    {{dataStudyingById.dataStudent.fullNameTh}}</p>
                  <p>ชื่อ (ภาษาอังกฤษ) :
                    {{dataStudyingById.dataStudent.fullNameEn}}</p>
                  <p>{{'EXECUTIVE.E-MAIL' | translate}} :
                    {{dataStudyingById.dataStudent.email}}</p>
                  <p>{{'EXECUTIVE.PHONE-NUMBER' | translate}} :
                    {{dataStudyingById.dataStudent.phoneNumber}}</p>
                </div>
              </app-card>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <app-card [hidHeader]="true" blockClass="user-radial-card">
                <div style="text-align: left;height: 159px;">
                  <p>{{'ADMIN.MEMBER.POSITION'|translate}} :
                    {{dataStudyingById.dataStudent.studentPosition}}</p>
                  <p>{{'ADMIN.MEMBER.AGENCY'|translate}} :
                    {{dataStudyingById.dataStudent.studentMinistry}}</p>
                  <p>{{'ADMIN.MEMBER.DEPARTMENT'|translate}} :
                    {{dataStudyingById.dataStudent.dataDepartment.name}}</p>
                  <p>{{'ADMIN.MEMBER.DIVISION'|translate}} :
                    {{dataStudyingById.dataStudent.studentDivision}}</p>
                  <p>เงื่อนไขการสอบ :
                    {{dataStudyingById.dataExamfinal.detail}}</p>
                  <p>จำนวนข้อสอบ :
                    {{dataStudyingById.dataExamfinal.numberOfExam}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '30'"> เวลาในการทำข้อสอบ : 30
                    {{'ADMIN.COURSE.MINUTE' |
                    translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '60'"> เวลาในการทำข้อสอบ : 1
                    {{'ADMIN.COURSE.HOUR'
                    |
                    translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '90'"> เวลาในการทำข้อสอบ : 1
                    {{'ADMIN.COURSE.HOUR'
                    |
                    translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '120'"> เวลาในการทำข้อสอบ : 2
                    {{'ADMIN.COURSE.HOUR' |
                    translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '150'"> เวลาในการทำข้อสอบ : 2
                    {{'ADMIN.COURSE.HOUR' |
                    translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '180'"> เวลาในการทำข้อสอบ : 3
                    {{'ADMIN.COURSE.HOUR' |
                    translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '210'"> เวลาในการทำข้อสอบ : 3
                    {{'ADMIN.COURSE.HOUR' |
                    translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '240'"> เวลาในการทำข้อสอบ : 4
                    {{'ADMIN.COURSE.HOUR' |
                    translate}}</p>
                  <p *ngIf="dataStudyingById.dataExamfinal.timeExam == '270'"> เวลาในการทำข้อสอบ : 4
                    {{'ADMIN.COURSE.HOUR' |
                    translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</p>
                </div>
              </app-card>
            </div>
          </div>
          <hr style="margin-top: -5px;">
          <div style="display: flex;justify-content: space-between;margin-top: 10px;">
            <span>
              <p>{{'EXECUTIVE.SCORE' | translate}} :
                {{dataStudyingById.dataExamfinal.conditionExam}} {{'EXECUTIVE.SCORE' |
                translate}}</p>
            </span>
            <span>
              <p>ความคืบหน้า : <span class="badge badge-pill badge-light-true"
                  style="margin-left: 10px;">{{dataStudyingById.progress}} %</span></p>
            </span>
            <span>
              <p>{{'TEACHER.STATUS' | translate}} : <span class="badge badge-pill badge-file"
                  style="margin-left: 10px;">กำลังเรียน</span></p>
            </span>
          </div>
        </div>
      </div>
    </app-ui-modal>

  </div>
</app-card>


<table witdh="100%" border="1" class="table" #table11 id="excel-table-ReportTop10" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
      <th>{{'EXECUTIVE.TEACHER-ID' | translate}}</th>
      <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="detailTeachers != undefined">
      <td>{{detailTeachers.por.fullNameTh}}</td>
      <td>{{detailTeachers.por.fullNameEn}}</td>
      <td>{{detailTeachers.por.username}}</td>
      <td>{{detailTeachers.por.tchId}}</td>
      <td>
        {{detailTeachers.por.citizenId[0]}}-{{detailTeachers.por.citizenId[1]}}{{detailTeachers.por.citizenId[2]}}{{detailTeachers.por.citizenId[3]}}{{detailTeachers.por.citizenId[4]}}-{{detailTeachers.por.citizenId[5]}}{{detailTeachers.por.citizenId[6]}}{{detailTeachers.por.citizenId[7]}}{{detailTeachers.por.citizenId[8]}}{{detailTeachers.por.citizenId[9]}}-{{detailTeachers.por.citizenId[10]}}{{detailTeachers.por.citizenId[11]}}-{{detailTeachers.por.citizenId[12]}}
      </td>
      <td>{{detailTeachers.email}}</td>
      <td>
        {{detailTeachers.por.phoneNumber[0]}}{{detailTeachers.por.phoneNumber[1]}}{{detailTeachers.por.phoneNumber[2]}}-{{detailTeachers.por.phoneNumber[3]}}{{detailTeachers.por.phoneNumber[4]}}{{detailTeachers.por.phoneNumber[5]}}-{{detailTeachers.por.phoneNumber[6]}}{{detailTeachers.por.phoneNumber[7]}}{{detailTeachers.por.phoneNumber[8]}}{{detailTeachers.por.phoneNumber[9]}}
      </td>
      <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-MOST-POPULAR-COURSES' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'TEACHER.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'TEACHER.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.SCORE' | translate}}</th>
      <th>{{'EXECUTIVE.NUMBER-PROPLE-RATING' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topRating != null">
    <tr *ngFor="let topBest of topRating.topBest;index as i">
      <td>{{'EXECUTIVE.RANK' | translate}} {{i+1}}</td>
      <td>{{topBest.nameTh}}</td>
      <td>{{topBest.nameEn}}</td>
      <td>{{topBest.scroreRating}} {{'EXECUTIVE.SCORE' | translate}}</td>
      <td>{{topBest.countRating}} {{'EXECUTIVE.PEOPLE' | translate}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.TOP-10-OF-THE-LEAST-POPULAR-COURSES' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ARRANGE' | translate}}</th>
      <th>{{'TEACHER.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'TEACHER.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'EXECUTIVE.SCORE' | translate}}</th>
      <th>{{'EXECUTIVE.NUMBER-PROPLE-RATING' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="topRating != null">
    <tr *ngFor="let topBest of topRating.topWorst;index as i">
      <td>{{'EXECUTIVE.RANK' | translate}} {{i+1}}</td>
      <td>{{topBest.nameTh}}</td>
      <td>{{topBest.nameEn}}</td>
      <td>{{topBest.scroreRating}} {{'EXECUTIVE.SCORE' | translate}}</td>
      <td>{{topBest.countRating}} {{'EXECUTIVE.PEOPLE' | translate}}</td>
    </tr>
  </tbody>
</table>

<table witdh="100%" border="1" class="table" #table11 id="excel-table-Subject-All" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.USER-DATA' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'EXECUTIVE.USERNAME' | translate}}</th>
      <th>{{'EXECUTIVE.TEACHER-ID' | translate}}</th>
      <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.DATE-OF-BIRTH' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="detailTeachers != undefined">
      <td>{{detailTeachers.por.fullNameTh}}</td>
      <td>{{detailTeachers.por.fullNameEn}}</td>
      <td>{{detailTeachers.por.username}}</td>
      <td>{{detailTeachers.por.tchId}}</td>
      <td>
        {{detailTeachers.por.citizenId[0]}}-{{detailTeachers.por.citizenId[1]}}{{detailTeachers.por.citizenId[2]}}{{detailTeachers.por.citizenId[3]}}{{detailTeachers.por.citizenId[4]}}-{{detailTeachers.por.citizenId[5]}}{{detailTeachers.por.citizenId[6]}}{{detailTeachers.por.citizenId[7]}}{{detailTeachers.por.citizenId[8]}}{{detailTeachers.por.citizenId[9]}}-{{detailTeachers.por.citizenId[10]}}{{detailTeachers.por.citizenId[11]}}-{{detailTeachers.por.citizenId[12]}}
      </td>
      <td>{{detailTeachers.por.email}}</td>
      <td>
        {{detailTeachers.por.phoneNumber[0]}}{{detailTeachers.por.phoneNumber[1]}}{{detailTeachers.por.phoneNumber[2]}}-{{detailTeachers.por.phoneNumber[3]}}{{detailTeachers.por.phoneNumber[4]}}{{detailTeachers.por.phoneNumber[5]}}-{{detailTeachers.por.phoneNumber[6]}}{{detailTeachers.por.phoneNumber[7]}}{{detailTeachers.por.phoneNumber[8]}}{{detailTeachers.por.phoneNumber[9]}}
      </td>
      <td>{{address[0]}},{{address[3]}}{{address[2]}},{{address[1]}},{{address[4]}}</td>
    </tr>
  </tbody>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.ALL-COURSES' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'TEACHER.THAI-NAME-COURSES' | translate}}</th>
      <th>{{'TEACHER.ENGLISH-NAME-COURSES' | translate}}</th>
      <th>{{'TEACHER.AMOUNT-OF-STUDENT' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngIf="reportSubjectAll != null">
    <tr *ngFor="let Subject of reportSubjectAll;index as i">
      <td>{{Subject.nameTh}}</td>
      <td>{{Subject.nameEn}}</td>
      <td>{{Subject.countStudentLearn}} {{'EXECUTIVE.PEOPLE' | translate}}</td>
    </tr>
  </tbody>
</table>

<!-- รายงานผู้เรียนจบตามรายหลักสูตร -->
<table witdh="100%" border="1" class="table" #table11 id="excel-table-Find-Report-Student-Graduate-Course"
  style="display: none;" *ngIf="dataGraduateTheCourse != null">
  <thead>
    <tr *ngIf="dataGraduateTheCourse != null">
      <th>{{'EXECUTIVE.ALL-LEARNERS' | translate}}
        {{dataGraduateTheCourse.countListAll}} {{'EXECUTIVE.ITEM' |
        translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr *ngIf="dataGraduateTheCourse != null">
      <th>{{'EXECUTIVE.STUDENT-COMPLETE-COURSE' | translate}}
        {{dataGraduateTheCourse.countListSudentGraduate}} {{'EXECUTIVE.PEOPLE'
        | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.INFORMATION-STUDENT-COMPLETE-COURSE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'ADMIN.MEMBER.AGENCY'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DEPARTMENT'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DIVISION'|translate}}</th>
      <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      <th>{{'EXECUTIVE.EXAM-CONDITION' | translate}}</th>
      <th>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}}</th>
      <th>{{'EXECUTIVE.SCORE' | translate}}</th>
      <th>{{'TEACHER.TIME-FOR-EXAM' | translate}}</th>
      <th>{{'TEACHER.STATUS' | translate}}</th>
      <th>{{'EXECUTIVE.COURSE-CODE' | translate}}</th>
      <th>{{'EXECUTIVE.SUBJECT' | translate}}</th>
      <th>ว/ด/ปี (ลงทะเบียนเรียน)</th>
      <th>ว/ด/ปี (เรียนจบ)</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}}</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngFor="let item of dataGraduateTheCourse.dataDetail">
    <tr *ngIf="item.dataGraduate != null && item.dataGraduate.statusResult == 'successful'">
      <td>{{item.dataStudent.fullNameTh}}</td>
      <td>{{item.dataStudent.fullNameEn}}</td>
      <td>{{item.dataStudent.email}}</td>
      <td>กระทรวงเกษตรและสหกรณ์</td>
      <td>{{item.dataStudent.dataDepartment.name}}</td>
      <td>{{item.dataStudent.studentDivision}}</td>
      <td>
        {{item.dataStudent.citizenId[0]}}-{{item.dataStudent.citizenId[1]}}{{item.dataStudent.citizenId[2]}}{{item.dataStudent.citizenId[3]}}{{item.dataStudent.citizenId[4]}}-{{item.dataStudent.citizenId[5]}}{{item.dataStudent.citizenId[6]}}{{item.dataStudent.citizenId[7]}}{{item.dataStudent.citizenId[8]}}{{item.dataStudent.citizenId[9]}}-{{item.dataStudent.citizenId[10]}}{{item.dataStudent.citizenId[11]}}-{{item.dataStudent.citizenId[12]}}
      </td>
      <td>
        {{item.dataStudent.phoneNumber[0]}}{{item.dataStudent.phoneNumber[1]}}{{item.dataStudent.phoneNumber[2]}}-{{item.dataStudent.phoneNumber[3]}}{{item.dataStudent.phoneNumber[4]}}{{item.dataStudent.phoneNumber[5]}}-{{item.dataStudent.phoneNumber[6]}}{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
      </td>
      <td>{{item.dataStudent.address}}</td>
      <td>{{item.dataGraduate.conditionExam}}</td>
      <td>{{item.dataGraduate.numberOfExam}}</td>
      <td>{{item.dataGraduate.score}}</td>
      <td>{{item.dataGraduate.timeExam}} {{'ADMIN.COURSE.MINUTE' |
        translate}}</td>
      <td>{{'ADMIN.Q-AND-A.COMPLETE' | translate}}</td>
      <td>{{item.dataGraduate.dataSubject.subjectId}}</td>
      <td>{{item.dataGraduate.dataSubject.nameTh}} (
        {{item.dataGraduate.dataSubject.nameEn}} )</td>
      <td>{{item.dataGraduate.dataSubject.startDateSubject.start| date:'medium'}}</td>
      <td>{{item.dataGraduate.dataSubject.startDateSubject.end| date:'medium'}}</td>
      <td>{{item.dataGraduate.dataSubject.dataTeacher.fullNameTh}}</td>
      <td>{{item.dataGraduate.dataSubject.dataTeacher.fullNameEn}}</td>
      <td>{{item.dataGraduate.dataSubject.dataTeacher.ermail}}</td>
      <td>
        {{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[0]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[1]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[2]}}-{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[3]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[4]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[5]}}-{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[6]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[7]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[8]}}{{item.dataGraduate.dataSubject.dataTeacher.phoneNumber[9]}}
      </td>
    </tr>
  </tbody>
</table>

<!-- รายงานสถิติจำนวนผู้เข้าเรียนและพิมพ์ใบรับรอง -->
<table witdh="100%" border="1" class="table" #table11 id="excel-table-Find-Report-Count-Student-And-Certificate"
  style="display: none;" *ngIf="dataStudentAndCertificate != null">
  <thead>
    <tr *ngIf="dataStudentAndCertificate != null">
      <th>{{'EXECUTIVE.ALL-LEARNERS' | translate}}
        {{dataStudentAndCertificate.countListAll}} {{'EXECUTIVE.ITEM' |
        translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr *ngIf="dataStudentAndCertificate != null">
      <th>{{'EXECUTIVE.CERTIFICATE-HOLDER' | translate}}
        {{dataStudentAndCertificate.countListCertificate}} {{'EXECUTIVE.PEOPLE'
        | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr></tr>
  </thead>
  <thead>
    <tr>
      <th>{{'EXECUTIVE.STATISTICS-REGISTER-CERTIFICATE' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'ADMIN.MEMBER.AGENCY'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DEPARTMENT'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DIVISION'|translate}}</th>
      <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
      <th>{{'EXECUTIVE.CERTIFICATE-DATE' | translate}}</th>
      <th>{{'EXECUTIVE.COURSE-CODE' | translate}}</th>
      <th>{{'EXECUTIVE.SUBJECT' | translate}}</th>
      <th>ว/ด/ปี (พิมพ์ใบรับรอง)</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}}</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngFor="let item of dataStudentAndCertificate.dataDetail">
    <tr *ngIf="item.dataCertificateStudent != null">
      <td>{{item.dataStudent.fullNameTh}}</td>
      <td>{{item.dataStudent.fullNameEn}}</td>
      <td>{{item.dataStudent.email}}</td>
      <td>กระทรวงเกษตรและสหกรณ์</td>
      <td>{{item.dataStudent.dataDepartment.name}}</td>
      <td>{{item.dataStudent.studentDivision}}</td>
      <td>
        {{item.dataStudent.citizenId[0]}}-{{item.dataStudent.citizenId[1]}}{{item.dataStudent.citizenId[2]}}{{item.dataStudent.citizenId[3]}}{{item.dataStudent.citizenId[4]}}-{{item.dataStudent.citizenId[5]}}{{item.dataStudent.citizenId[6]}}{{item.dataStudent.citizenId[7]}}{{item.dataStudent.citizenId[8]}}{{item.dataStudent.citizenId[9]}}-{{item.dataStudent.citizenId[10]}}{{item.dataStudent.citizenId[11]}}-{{item.dataStudent.citizenId[12]}}
      </td>
      <td>
        {{item.dataStudent.phoneNumber[0]}}{{item.dataStudent.phoneNumber[1]}}{{item.dataStudent.phoneNumber[2]}}-{{item.dataStudent.phoneNumber[3]}}{{item.dataStudent.phoneNumber[4]}}{{item.dataStudent.phoneNumber[5]}}-{{item.dataStudent.phoneNumber[6]}}{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
      </td>
      <td>{{item.dataStudent.address}}</td>
      <td>{{item.dataCertificateStudent.dateCreate | date:'medium'}}</td>
      <td>{{item.dataCertificateStudent.dataSubject.subjectId}}</td>
      <td>{{item.dataCertificateStudent.dataSubject.nameTh}} (
        {{item.dataCertificateStudent.dataSubject.nameEn}} )</td>
      <td>{{item.dataCertificateStudent.dataSubject.startDateSubject.start | date:'medium'}}</td>
      <td>{{item.dataCertificateStudent.dataSubject.dataTeacher.fullNameTh}}</td>
      <td>{{item.dataCertificateStudent.dataSubject.dataTeacher.fullNameEn}}</td>
      <td>{{item.dataCertificateStudent.dataSubject.dataTeacher.ermail}}</td>
      <td>
        {{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[0]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[1]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[2]}}-{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[3]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[4]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[5]}}-{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[6]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[7]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[8]}}{{item.dataCertificateStudent.dataSubject.dataTeacher.phoneNumber[9]}}
      </td>
  </tbody>
</table>

<!-- รายงานผลการสอบก่อนเรียน -->
<div *ngIf="dataExamSubject != null">
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Pre-test" style="display: none;"
    *ngIf="dataExamSubject.pretest != null && dataExamSubject.pretest != '' && dataExamSubject.pretest != undefined ">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.PRETEST-RESULT' | translate}}
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.nameTh}} (
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.nameEn}} )</th>
      </tr>
    </thead>
    <thead>
      <tr *ngIf="dataExamSubject.pretest[0] != []">
        <th>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}} :
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.tchId.fullNameTh}}</th>
        <th>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}:
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.tchId.fullNameEn}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}} :
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.tchId.ermail}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}} :
          {{dataExamSubject.pretest[0].dataPreTest.dataSubject.tchId.phoneNumber}}</th>
      </tr>
    </thead>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.ALL-CONTACT' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-CONDITION' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-COMPLETED' | translate}}</th>
        <th>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}}</th>
        <th>{{'EXECUTIVE.SCORE' | translate}}</th>
        <th>{{'TEACHER.TIME-FOR-EXAM' | translate}}</th>
        <th>{{'TEACHER.STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngFor="let item of dataExamSubject.pretest;index as i">
      <tr>
        <td>{{item.dataStudent.fullNameTh}}</td>
        <td>{{item.dataStudent.fullNameEn}}</td>
        <td>{{item.dataStudent.email}}</td>
        <td>
          {{item.dataStudent.citizenId[0]}}-{{item.dataStudent.citizenId[1]}}{{item.dataStudent.citizenId[2]}}{{item.dataStudent.citizenId[3]}}{{item.dataStudent.citizenId[4]}}-{{item.dataStudent.citizenId[5]}}{{item.dataStudent.citizenId[6]}}{{item.dataStudent.citizenId[7]}}{{item.dataStudent.citizenId[8]}}{{item.dataStudent.citizenId[9]}}-{{item.dataStudent.citizenId[10]}}{{item.dataStudent.citizenId[11]}}-{{item.dataStudent.citizenId[12]}}
        </td>
        <td>
          {{item.dataStudent.phoneNumber[0]}}{{item.dataStudent.phoneNumber[1]}}{{item.dataStudent.phoneNumber[2]}}-{{item.dataStudent.phoneNumber[3]}}{{item.dataStudent.phoneNumber[4]}}{{item.dataStudent.phoneNumber[5]}}-{{item.dataStudent.phoneNumber[6]}}{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
        </td>
        <td>{{item.dataStudent.address}}</td>
        <td>{{item.dataPreTest.conditionExam}}</td>
        <td>{{item.dataPreTest.date | date:'medium'}}</td>
        <td>{{item.dataPreTest.numberOfExam}}</td>
        <td>{{item.dataPreTest.score}}</td>
        <td>{{item.dataPreTest.timeExam}} {{'ADMIN.COURSE.MINUTE' |
          translate}}</td>
        <td>{{item.dataPreTest.statusResult}}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- รายงานผลการสอบหลังเรียน -->
<div *ngIf="dataExamSubject != null">
  <table witdh="100%" border="1" class="table" #table11 id="excel-table-Post-test" style="display: none;"
    *ngIf="dataExamSubject.posttest != null && dataExamSubject.posttest != '' && dataExamSubject.posttest != undefined ">
    <thead>
      <tr>
        <th>{{'EXECUTIVE.FINAL-EXAM-RESULTS' | translate}}
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.nameTh}}
          (
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.nameEn}}
          )</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}} :
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.dataTeacher.fullNameTh}}</th>
        <th>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}:
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.dataTeacher.fullNameEn}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}} :
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.dataTeacher.ermail}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}} :
          {{dataExamSubject.posttest[0].dataPostTest[0].dataSubject.dataTeacher.phoneNumber}}
        </th>
      </tr>
    </thead>
    <thead>
      <tr></tr>
    </thead>
    <thead>
      <tr>
        <th>{{'EXECUTIVE.ALL-CONTACT' | translate}}</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
        <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
        <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
        <th>{{'EXECUTIVE.ID-CARD' | translate}}</th>
        <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
        <th>{{'EXECUTIVE.CURRENT-ADDRESS' | translate}}</th>

        <th>{{'EXECUTIVE.EXAM-CONDITION' | translate}}</th>
        <th>{{'EXECUTIVE.EXAM-COMPLETED' | translate}}</th>
        <th>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}}</th>
        <th>{{'EXECUTIVE.SCORE' | translate}}</th>
        <th>{{'TEACHER.TIME-FOR-EXAM' | translate}}</th>
        <th>{{'TEACHER.STATUS' | translate}}</th>
      </tr>
    </thead>
    <tbody *ngFor="let item of dataExamSubject.posttest">
      <tr>
        <td>{{item.dataStudent.fullNameTh}}</td>
        <td>{{item.dataStudent.fullNameEn}}</td>
        <td>{{item.dataStudent.email}}</td>
        <td>
          {{item.dataStudent.citizenId[0]}}-{{item.dataStudent.citizenId[1]}}{{item.dataStudent.citizenId[2]}}{{item.dataStudent.citizenId[3]}}{{item.dataStudent.citizenId[4]}}-{{item.dataStudent.citizenId[5]}}{{item.dataStudent.citizenId[6]}}{{item.dataStudent.citizenId[7]}}{{item.dataStudent.citizenId[8]}}{{item.dataStudent.citizenId[9]}}-{{item.dataStudent.citizenId[10]}}{{item.dataStudent.citizenId[11]}}-{{item.dataStudent.citizenId[12]}}
        </td>
        <td>
          {{item.dataStudent.phoneNumber[0]}}{{item.dataStudent.phoneNumber[1]}}{{item.dataStudent.phoneNumber[2]}}-{{item.dataStudent.phoneNumber[3]}}{{item.dataStudent.phoneNumber[4]}}{{item.dataStudent.phoneNumber[5]}}-{{item.dataStudent.phoneNumber[6]}}{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
        </td>
        <td>{{item.dataStudent.address}}</td>
      </tr>
      <tr *ngFor="let itemPostTest of item.dataPostTest">
        <td>{{item.dataStudent.fullNameTh}}</td>
        <td>{{item.dataStudent.fullNameEn}}</td>
        <td>{{item.dataStudent.email}}</td>
        <td>
          {{item.dataStudent.citizenId[0]}}-{{item.dataStudent.citizenId[1]}}{{item.dataStudent.citizenId[2]}}{{item.dataStudent.citizenId[3]}}{{item.dataStudent.citizenId[4]}}-{{item.dataStudent.citizenId[5]}}{{item.dataStudent.citizenId[6]}}{{item.dataStudent.citizenId[7]}}{{item.dataStudent.citizenId[8]}}{{item.dataStudent.citizenId[9]}}-{{item.dataStudent.citizenId[10]}}{{item.dataStudent.citizenId[11]}}-{{item.dataStudent.citizenId[12]}}
        </td>
        <td>
          {{item.dataStudent.phoneNumber[0]}}{{item.dataStudent.phoneNumber[1]}}{{item.dataStudent.phoneNumber[2]}}-{{item.dataStudent.phoneNumber[3]}}{{item.dataStudent.phoneNumber[4]}}{{item.dataStudent.phoneNumber[5]}}-{{item.dataStudent.phoneNumber[6]}}{{item.dataStudent.phoneNumber[7]}}{{item.dataStudent.phoneNumber[8]}}{{item.dataStudent.phoneNumber[9]}}
        </td>
        <td>{{item.dataStudent.address}}</td>
        <td>{{itemPostTest.conditionExam}}</td>
        <td>{{itemPostTest.date | date:'medium'}}</td>
        <td>{{itemPostTest.numberOfExam}}</td>
        <td>{{itemPostTest.score}}</td>
        <!-- <td>{{itemPostTest.timeExam}} {{'ADMIN.COURSE.MINUTE' | translate}}</td> -->
        <td *ngIf="itemPostTest.timeExam == '30'">30 {{'ADMIN.COURSE.MINUTE' |
          translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '60'">1 {{'ADMIN.COURSE.HOUR' |
          translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '90'">1 {{'ADMIN.COURSE.HOUR' |
          translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '120'">2 {{'ADMIN.COURSE.HOUR' |
          translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '150'">2 {{'ADMIN.COURSE.HOUR' |
          translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '180'">3 {{'ADMIN.COURSE.HOUR' |
          translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '210'">3 {{'ADMIN.COURSE.HOUR' |
          translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '240'">4 {{'ADMIN.COURSE.HOUR' |
          translate}}</td>
        <td *ngIf="itemPostTest.timeExam == '270'">4 {{'ADMIN.COURSE.HOUR' |
          translate}} 30 {{'ADMIN.COURSE.MINUTE' | translate}}</td>
        <td>{{itemPostTest.statusResult}}</td>
      </tr>
    </tbody>
  </table>
</div>


<!-- รายงานข้อมูลผู้เรียน สถานะ  "กำลังเรียน"  ตามรายหลักสูตร -->
<table witdh="100%" border="1" class="table" #table11 id="excel-table-Student-Information-Report"
  style="display: none;">
  <thead>
    <tr>
      <th>รายงานข้อมูลผู้เรียน สถานะ "กำลังเรียน" ตามรายหลักสูตร</th>
    </tr>
  </thead>
  <thead>
    <tr *ngIf="studyingCount != null">
      <th>จำนวนผู้เรียน สถานะกำลังเรียน {{studyingCount.length}} คน</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'ADMIN.MEMBER.AGENCY'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DEPARTMENT'|translate}}</th>
      <th>{{'ADMIN.MEMBER.DIVISION'|translate}}</th>
      <th>{{'EXECUTIVE.EXAM-CONDITION' | translate}}</th>
      <th>{{'TEACHER.AMOUNT-OF-EXAMINATION' | translate}}</th>
      <th>{{'EXECUTIVE.SCORE' | translate}}</th>
      <th>{{'TEACHER.TIME-FOR-EXAM' | translate}}</th>
      <th>{{'TEACHER.STATUS' | translate}}</th>
      <th>{{'EXECUTIVE.COURSE-CODE' | translate}}</th>
      <th>{{'EXECUTIVE.SUBJECT' | translate}}</th>
      <th>ว/ด/ปี (ลงทะเบียนเรียน)</th>
      <th>(%) ความคืบหน้าของผู้เรียน</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-TH' | translate}}</th>
      <th>{{'EXECUTIVE.INSTRUCTOR-NAME-EN' | translate}}</th>
      <th>{{'EXECUTIVE.E-MAIL' | translate}}</th>
      <th>{{'EXECUTIVE.PHONE-NUMBER' | translate}}</th>
    </tr>
  </thead>
  <tbody *ngFor="let item of studyingCount">
    <tr>
      <td>{{item.dataStudent.fullNameTh}}</td>
      <td>{{item.dataStudent.fullNameEn}}</td>
      <td>{{item.dataStudent.email}}</td>
      <td>กระทรวงเกษตรและสหกรณ์</td>
      <td>{{item.dataStudent.dataDepartment.name}}</td>
      <td>{{item.dataStudent.studentDivision}}</td>
      <td>{{item.dataExamfinal.detail}}</td>
      <td>{{item.dataExamfinal.numberOfExam}}</td>
      <td>{{item.dataExamfinal.conditionExam}}</td>
      <td>{{item.dataExamfinal.timeExam}} {{'ADMIN.COURSE.MINUTE' |
        translate}}</td>
      <td>กำลังเรียน</td>
      <td>{{item.dataExamfinal.dataSubject.subjectId}}</td>
      <td>{{item.dataExamfinal.dataSubject.nameTh}} (
        {{item.dataExamfinal.dataSubject.nameEn}} )</td>
      <td>{{item.dataBuyCourse.date | date:'medium'}}</td>
      <td>{{item.progress}} เปอร์เซ็น</td>
      <td>{{item.dataExamfinal.dataSubject.dataTeacher.fullNameTh}}</td>
      <td>{{item.dataExamfinal.dataSubject.dataTeacher.fullNameEn}}</td>
      <td>{{item.dataExamfinal.dataSubject.dataTeacher.ermail}}</td>
      <td>
        {{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[0]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[1]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[2]}}-{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[3]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[4]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[5]}}-{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[6]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[7]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[8]}}{{item.dataExamfinal.dataSubject.dataTeacher.phoneNumber[9]}}
      </td>
    </tr>
  </tbody>
</table>

<!-- <div class="col-lg-12 col-md-12"> -->

<!-- </div> -->