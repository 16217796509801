import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { DataSubject } from 'src/app/core/interfaces/admin-interface/response-find-course-student-interface';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-usage-statistics',
  templateUrl: './report-usage-statistics.component.html',
  styleUrls: ['./report-usage-statistics.component.scss'],
})
export class ReportUsageStatisticsComponent implements OnInit {
  public isCompleteStatus = false;
  public isAssignUsers = false;
  public isRevision = false;
  public ind;
  urlImage = Constants.REST_API_MAIN;
  public chartDB: any;
  dataStudentAll: Array<any> = [];
  selectedStudent: any = '';
  studentBuyCouses: any;
  studentCourseList: any;
  studentBuyCouses2: any;
  address: any;
  dataLoading1: boolean = true;
  dataLoading2: boolean = true;
  dataLoading3: boolean = true;
  dataSubject: DataSubject[];
  detailStudent: any;
  customerAnalytics1: any;
  customerAnalytics2: any;
  StudentAndTeacher: any;
  CourseSubject: any;
  CommentRating: any;
  Certificate: any;
  dateCertificate: any;

  studentExamData: any = [];
  examDataToShow: any;
  examDataToShow2: any;
  examDataToShowFinal: any;
  postTestDataToShow: any;
  lessonData: any;
  loading1: boolean = true;
  loading2: boolean = true;

  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) { }

  ngOnInit() {
    this.getStudent();
    this.onChangSelectPortal();
  }

  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.dataStudentAll = [];
        this.getStudent();
      }
    });
  }

  async getStudent() {
    var getSuccess: any;
    var data: any = [];
    await this.adminService.fetchDataAllMemmerUser().then((response) => {
      getSuccess = response;
      // console.log("res ", response);

      for (let i = 0; i < getSuccess.dataStudents.length; i++) {
        data.push({
          value: getSuccess.dataStudents[i].dataStudents.stdntId,
          label: getSuccess.dataStudents[i].dataStudents.fullNameTh,
        });
        this.dataStudentAll = data;
      }
      // console.log("cc ", this.dataStudentAll[0]);

      this.selectedStudent = this.dataStudentAll.length > 0 ? this.dataStudentAll[0].value : "";
      this.getCourseList(this.selectedStudent);
      this.getDetailStudent(this.selectedStudent);
      this.loadStudentExamData();

    });
  }

  loadStudentExamData() {
    this.adminService.FindStudentTest(this.selectedStudent).then((message) => {
      // console.log(">> ", message);

      if (message.status) {
        this.studentExamData = message.data;
      } else {
        this.studentExamData = [];
      }
    });
  }

  getDetailStudent(studentId: any) {
    var detailStd: any;
    this.adminService.fetchDataAllMemmerUser().then((res) => {
      detailStd = res;
      this.detailStudent = detailStd.dataStudents.find(
        (s) => s.dataStudents.stdntId == studentId
      );
      this.getDataCourses(studentId);
    });
  }

  getDataCourses(studentId: any) {
    var array = [];
    var array2 = [];
    var data: any = [];
    var checklearnStudent: boolean = false;
    this.studentBuyCouses = [];
    var setStudentBuyCouses = [];
    var learningWorst: number = 0;
    this.adminService.FindSudentBuyCoursesList(studentId).then((res) => {
      if (res.status == true) {
        if (res.status) {
          setStudentBuyCouses = res.studentBuyCouses;

          for (let i = 0; i < res.studentBuyCouses.length; i++) {
            checklearnStudent = false;
            learningWorst = 0;
            for (
              let j = 0;
              j < res.studentBuyCouses[i].dataLearns.length;
              j++
            ) {
              if (res.studentBuyCouses[i].dataLearns[j].learnStatus) {
                checklearnStudent = true;
                learningWorst++;
              } else {
                checklearnStudent = false;
              }
            }
            setStudentBuyCouses[i]['studentlearnSuccess'] = checklearnStudent;
            setStudentBuyCouses[i]['studentlearnWorst'] =
              res.studentBuyCouses[i].dataLearns.length > 0
                ? (learningWorst * 100) /
                res.studentBuyCouses[i].dataLearns.length
                : 0;
          }
        }

        for (let index = 0; index < setStudentBuyCouses.length; index++) {
          if (setStudentBuyCouses[index].studentlearnSuccess) {
            array.push(setStudentBuyCouses[index]);
          } else {
            array2.push(setStudentBuyCouses[index]);
          }

          if (index == setStudentBuyCouses.length - 1) {
            this.studentBuyCouses = array;
            this.studentBuyCouses2 = array2;
          }
        }
        this.dataLoading1 = false;
      } else {
        this.studentBuyCouses = null;
        this.studentBuyCouses2 = null;
        this.dataLoading1 = false;
      }
    });
  }

  getDataSubjectStudent(idstd: any) {
    this.dataLoading1 = true;
    this.dataLoading2 = true;
    this.dataLoading3 = true;

    this.getCourseList(idstd);
    this.ind = null;
    this.getDetailStudent(idstd);
  }

  getCourseList(studentId) {
    this.adminService.FindCourseStudentList().then((res) => {

      if (res.status == true) {
        // console.log("res : ", res);

        var dataFind = res.dataCoursesStudent.find((s) => s.stdntId == studentId);
        // console.log("file : ", dataFind);
        if (dataFind != undefined && dataFind.dataSubject.length > 0) {
          this.dataSubject = dataFind.dataSubject;
        } else {
          this.dataSubject = null;
        }

        this.loadStudentExamData();
        this.dataSubjectAll();
        this.dataLoading1 = false;
      } else {
        this.dataSubject = null;
        this.dataLoading1 = false;
      }
    });
  }

  async onClick(e, subjectId) {
    await this.loading2 == true;

    this.examDataToShowFinal = null;
    this.postTestDataToShow = null;
    var array = [];
    var array2 = [];
    var array3 = [];
    var subjectID = subjectId;
    if (this.ind != null) {
      this.ind = null;
    } else {
      this.ind = e;
      for (let index = 0; index < this.studentExamData.length; index++) {
        if (subjectID == this.studentExamData[index].subjectId) {
          array.push(this.studentExamData[index]);
          break;
        }
      }
      // console.log("array ", array);

      if (array[0] != undefined && array[0] != null && array[0] != '') {
        this.examDataToShow = array[0].dataLesson;
        this.examDataToShow2 = array[0].dataPostTests;
        this.lessonData = array[0].dataLesson.length > 0 ? array[0].dataLesson[0].dataSubject.datalesson : [];

        var test;
        var pass = 0;
        var fail = 0;
        for (let index = 0; index < this.examDataToShow2.length; index++) {
          if (this.examDataToShow2[index].statusResult == 'successful') {
            pass += 1;
          } else if (this.examDataToShow2[index].statusResult == 'failed') {
            fail += 1;
          }
        }
        await array3.push({
          name: 'สอบหลังเรียน',
          count: this.examDataToShow2.length,
          passCount: pass,
          failCount: fail,
        });
        for (let x = 0; x < this.lessonData.length; x++) {
          var temp = this.lessonData[x].lessonId;
          test = this.examDataToShow.filter((x) => x.examsLessonId == temp);
          var win = 0;
          var lose = 0;
          for (let index = 0; index < test.length; index++) {
            if (test[index].testResultExamResults == 'successful') {
              win += 1;
            } else if (test[index].testResultExamResults == 'failed') {
              lose += 1;
            }
          }
          await array2.push({
            lessonData: this.lessonData[x],
            examData: test,
            examCount: test.length,
            passCount: win,
            notPassCount: lose,
          });
        }
        this.examDataToShowFinal = array2;
        this.postTestDataToShow = array3;
      } else {
        this.examDataToShowFinal = null;
        this.postTestDataToShow = null;
      }
    }
    setTimeout(() => {
      this.loading2 = false;

    }, 500);

  }
  dataSubjectAll() {
    this.loading1 = true;
    this.examDataToShowFinal = null;
    this.postTestDataToShow = null;
    var array2 = [];
    var array3 = [];
    if (this.dataSubject != null) {
      for (let i = 0; i < this.dataSubject.length; i++) {
        this.adminService.FindStudentTest(this.selectedStudent).then((message) => {
          if (message.status == true) {
            var array: any = [];

            this.studentExamData = message.data;
            for (let index = 0; index < this.studentExamData.length; index++) {
              if (this.dataSubject[i].data.subjectId == this.studentExamData[index].subjectId) {
                array = this.studentExamData[index];
                break;
              }
            }

            if (array != undefined && array != null && array != '') {
              if (array.dataPostTests.length != 0) {
                this.examDataToShow2 = array.dataPostTests;
              } else {
                this.examDataToShow2 = null;
              }
              if (array.dataLesson.length != 0) {
                this.examDataToShow = array.dataLesson;
                this.lessonData = array.dataLesson[0].dataSubject.datalesson;
              } else {
                this.examDataToShow = null;
                this.lessonData = null;
              }

              var test;
              var pass = 0;
              var fail = 0;
              if (this.examDataToShow2 != null) {
                for (let j = 0; j < this.examDataToShow2.length; j++) {
                  if (this.examDataToShow2[j].statusResult == 'successful') {
                    pass += 1;
                  } else if (this.examDataToShow2[j].statusResult == 'failed') {
                    fail += 1;
                  }
                }
              }

              array3.push({
                name: 'สอบหลังเรียน',
                count: this.examDataToShow2 != null ? this.examDataToShow2.length : 0,
                passCount: pass,
                failCount: fail,
              });

              if (this.lessonData != null) {
                for (let x = 0; x < this.lessonData.length; x++) {
                  var temp = this.lessonData[x].lessonId;
                  test = this.examDataToShow.filter((x) => x.examsLessonId == temp);
                  var win = 0;
                  var lose = 0;
                  for (let index = 0; index < test.length; index++) {
                    if (test[index].testResultExamResults == 'successful') {
                      win += 1;
                    } else if (test[index].testResultExamResults == 'failed') {
                      lose += 1;
                    }
                  }
                  array2.push({
                    lessonData: this.lessonData[x],
                    examData: test,
                    examCount: test.length,
                    passCount: win,
                    notPassCount: lose,
                  });
                }
              }
            }
          }
        });

        if (i == this.dataSubject.length - 1) {
          this.examDataToShowFinal = array2;
          this.postTestDataToShow = array3;
        }
      }
    } else {
      this.examDataToShowFinal = array2;
      this.postTestDataToShow = array3;
    }
    this.loading1 = false;
  }
  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
}
