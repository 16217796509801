<app-card [hidHeader]="true" cardClass="tab-card" blockClass="nav-fill">
  <!-- <div class="row" style="display: flex;justify-content: center;">
    <img src="assets/images/icon-gif/1_zVnWJtyGOX_kUIDm6ccCfQ.gif" style="width: 350px;" alt="">
  </div>
  <div class="row" style="display: flex;justify-content: center;">
    <p>----- อยู่ระหว่างการพัฒนา -----</p>
  </div> -->
  <!-- style="display: none;" -->
  <div class="row" style="justify-content: space-between;margin: unset;padding-bottom: 15px;">
    <div class="search-barrr">
      <input type="text" [(ngModel)]="searchModel" (ngModelChange)="searchChange($event)" placeholder="Search...">
      <i class="feather icon-search"></i>
    </div>
    <div class="date-filter">
      <button type="button" [class.active]="activeButton === 'all'" (click)="clearDateAllNew()"
        class="btn btn-date-filter">{{'ADMIN.contact-us.All' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'yesterday'" (click)="setDateRange('yesterday')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Yesterday' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last7'" (click)="setDateRange('last7')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 7 Days' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last15'" (click)="setDateRange('last15')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 15 Days' | translate}}</button>
      <button type="button" [class.active]="activeButton === 'last30'" (click)="setDateRange('last30')"
        class="btn btn-date-filter">{{'ADMIN.contact-us.Last 30 Days' | translate}}</button>
    </div>

    <!-- <button type="button" class="btn btn-icon btn-warning"
        style="border-radius: 50%; width: 35px; margin-left: 10px;" (click)="clearDateAll()">
        <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i>
      </button> -->

    <div style="justify-content: space-between;display: flex;">
      <div>
        <button type="button" class="btn custom-excel-button h-auto" data-toggle="tooltip" data-placement="bottom"
          title="{{'EXECUTIVE.DOWNLOAD-REPORT-A-USAGE-PROBLEM' | translate}}" style="float: right;"
          (click)="exportexcel('Report-problem')">
          <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
        </button>
      </div>
      <!-- <div>
          <label style="margin-right: 5px;"><b>Search : </b></label>
          <input type="date" id="start" name="bday" (change)="clearDateEnd()" [(ngModel)]="startDate">
          <label style="margin-right: 5px;margin-left: 5px;"><b>to</b></label>
          <input type="date" id="end" name="bday" [min]="startDate" [value]="endDate" (change)="searchDate($event)">
          <button type="button" class="btn btn-icon btn-warning"
            style="border-radius: 50%;width: 35px;margin-left: 10px;" (click)="clearDateAll()">
            <i class="feather icon-rotate-cw" style="margin-left: -4px;"></i></button>
        </div> -->

      <!-- <script>
          function myFunction() {
            var x = document.getElementById("start").min;
            document.getElementById("end").innerHTML = x;
          }
        </script> -->
    </div>
  </div>

  <!-- <app-card [hidHeader]="true" cardClass="user-profile-list"> -->
  <!-- <div class="dt-responsive table-responsive" *ngIf="dataProblem != null && dataProblem != ''">

    <table class="table table-striped row-border table-hover" *ngIf="!dataLoading">
      <thead>
        <tr>
          <th>{{'ADMIN.SUBJECT.NO' | translate}}</th>
          <th>{{'EXECUTIVE.REPORTERS-NAME' | translate}}</th>
          <th>{{'EXECUTIVE.TOPIC' | translate}}</th>
          <th>{{'TEACHER.DETAILS' | translate}}</th>
          <th>{{'EXECUTIVE.TIME' | translate}}</th>
          <th>{{'EXECUTIVE.SELECTION' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let problem of filteredUser;index as i">
          <td>{{i+1}}</td>
          <td style="text-align: left;">
            <div class="d-inline-block align-middle">
              <img src="{{baseURL}}/{{problem.dataStudent.image}}" alt="user image" class="img-radius align-top m-r-15"
                style="width:40px;height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
              <div class="d-inline-block">
                <p class="m-b-0">{{problem.dataStudent.fullNameTh}}</p>
          
              </div>
            </div>
          </td>
          <td style="text-overflow: ellipsis;overflow: hidden;width: 200px;text-align: left;">{{problem.title}}
          </td>
          <td style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 200px;text-align: left;">
            {{problem.description}}</td>
          <td>{{problem.date | date:'short'}}</td>
          <td>
            <span type="button" (click)="viewProblembyid(problem)"><i
                class="icon feather icon-eye f-16 text-i-view"></i></span>
          </td>
        </tr>
        <div *ngIf="filteredUser==''">
          <p style="color: rgb(175, 175, 175);margin:14px">ไม่พบข้อมูลการค้นหา!</p>
        </div>
    </table>
    <div class="pagination" style="margin-top: 12px;">
      <div>
        <button type="button" class="btn btn-outline-secondary" style="margin-right: 18px;"
          (click)="setPage(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>
        <button type="button" class="btn btn-outline-secondary" (click)="setPage(currentPage + 1)"
          [disabled]="currentPage === updatePagination()">Next</button>
      </div>
      <div>
        <span>Page {{currentPage}} of {{updatePagination()}}</span>
      </div>
    </div>
  </div> -->
  <div class="table-scroll-x">
    <table class="table-version-z">
      <thead>
        <tr>
          <th class="first text-center">{{'ADMIN.SUBJECT.NO' | translate}}</th>
          <th>{{'EXECUTIVE.REPORTERS-NAME' | translate}}</th>
          <th>{{'EXECUTIVE.TOPIC' | translate}}</th>
          <th>{{'ADMIN.COURSE.DETAILS' | translate}}</th>
          <th class="text-center">{{'EXECUTIVE.TIME' | translate}}</th>
          <th class="last text-center">{{'EXECUTIVE.SELECTION' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataProblem!=null && !dataLoading">

          <tr *ngFor="let itemDataProblem of dataProblem;index
            as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
            <td class="text-center">{{itemDataProblem.index}}</td>

            <td style="text-align: left;">

              <div class="d-flex flex-row align-items-center">
                <img src="{{baseURL}}/{{itemDataProblem.dataStudent.image}}" alt="user image"
                  class="img-radius align-top m-r-15" style="width:40px;height: 40px;object-fit: cover;"
                  onerror="this.src='assets/images/no-img.png'">
                <div class="d-inline-block">
                  <p class="m-b-0">{{itemDataProblem.dataStudent.fullNameTh}}</p>

                </div>
              </div>
            </td>
            <td style="text-overflow: ellipsis;overflow: hidden;width: 200px;text-align: left;">
              {{itemDataProblem.title}}
            </td>
            <td style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 200px;text-align: left;">
              {{itemDataProblem.description}}</td>
            <td class="text-center">{{itemDataProblem.date | date:'short'}}</td>
            <td class="text-center">
              <span type="button" (click)="viewProblembyid(itemDataProblem)"><i
                  class="icon feather icon-eye f-16 text-i-view"></i></span>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="dataProblem==null && !dataLoading">
          <tr>
            <td colspan="9" class="text-center"><strong>{{'ADMIN.NO-DATA-SHOWING' | translate}}</strong></td>
          </tr>
        </ng-container>
      </tbody>
    </table>

  </div>
  <div *ngIf="pageCount" class="page-control">
    <p style="padding-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} <span class="ml-1 mr-1">{{page}}</span>
      {{'ADMIN.Q-AND-A.OF' |
      translate}} <span class="ml-1">{{pageCount}}</span></p>
    <div>
      <button (click)="pageChange('prev')" class="prev">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
      <button (click)="pageChange('next')" class="next">{{'ADMIN.Q-AND-A.NEXT' | translate}}</button>
    </div>
  </div>


  <!-- <p *ngIf="!dataLoading && dataProblem == null||dataProblem==''" style="text-align: center;padding-top: 20px;">
    <b>*{{'EXECUTIVE.NO-DATA-SHOWING' | translate}}</b>
  </p> -->
  <div style="text-align: center;margin: 20px;">
    <div class="spinner-border text-info" role="status" *ngIf="dataLoading">
      <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
    </div>
  </div>
  <!-- </app-card> -->

</app-card>

<app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h6 class="modal-title">{{'EXECUTIVE.USAGER-PROBLEM-TOPIC' | translate}}</h6>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="myLargeModalLabel.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body" *ngIf="dataView != null && dataView != ''">
    <app-gallery *ngIf="type == 'images'" [albums]="singleGallery" style="text-align: center;"></app-gallery>
    <div style="text-align: center;">
      <video class="video-play-couse" *ngIf="type == 'videos'" controls style="max-width: 350px;text-align: center;">
        <source src="{{baseURL}}{{dataViewImg}}" type="video/mp4" />
      </video>
    </div>
    <p>{{'EXECUTIVE.PROBLEM-DETAIL' | translate}}</p>
    <p style="overflow-wrap: anywhere;">{{dataView.description}}</p>
  </div>
</app-ui-modal>

<table witdh="100%" border="1" class="table" #table11 id="excel-table-Report-problem" style="display: none;">
  <thead>
    <tr>
      <th>{{'EXECUTIVE.REPORT-A-USAGE-PROBLEM' | translate}}</th>
    </tr>
  </thead>
  <thead>
    <tr>
      <th>{{'ADMIN.MEMBER.NAME-THAI' | translate}}</th>
      <th>{{'ADMIN.MEMBER.NAME-ENGLISH' | translate}}</th>
      <th>{{'ADMIN.MEMBER.PHONE-NUMBER' | translate}}</th>
      <th>{{'EXECUTIVE.TOPIC' | translate}}</th>
      <th>{{'ADMIN.COURSE.DETAILS' | translate}}</th>
      <th>{{'EXECUTIVE.TIME' | translate}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let problem of dataProblem">
      <td>{{problem.dataStudent.fullNameTh}}</td>
      <td>{{problem.dataStudent.fullNameEn}}</td>
      <td>
        {{problem.dataStudent.phoneNumber[0]}}{{problem.dataStudent.phoneNumber[1]}}{{problem.dataStudent.phoneNumber[2]}}
        -
        {{problem.dataStudent.phoneNumber[3]}}{{problem.dataStudent.phoneNumber[4]}}{{problem.dataStudent.phoneNumber[5]}}
        -
        {{problem.dataStudent.phoneNumber[6]}}{{problem.dataStudent.phoneNumber[7]}}{{problem.dataStudent.phoneNumber[8]}}{{problem.dataStudent.phoneNumber[9]}}
      </td>
      <td>{{problem.title}}</td>
      <td>{{problem.description}}</td>
      <td>{{problem.date | date:'short'}}</td>
    </tr>
  </tbody>
</table>