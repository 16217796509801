import { Component, OnInit } from '@angular/core';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { DataSubject } from 'src/app/core/interfaces/admin-interface/response-find-course-student-interface';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { ChartDB } from 'src/app/fack-db/chart-data';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-courses-all-user',
  templateUrl: './report-courses-all-user.component.html',
  styleUrls: ['./report-courses-all-user.component.scss'],
})
export class ReportCoursesAllUserComponent implements OnInit {
  urlImage = Constants.REST_API_MAIN;
  public chartDB: any;
  dataStudentAll: any;
  selectedStudent: any = '';
  studentBuyCouses: any;
  studentCourseList: any;
  studentBuyCouses2: any;
  address: any;
  dataLoading1: boolean = true;
  dataLoading2: boolean = true;
  dataLoading3: boolean = true;
  dataSubject: DataSubject[];
  detailStudent: any;
  customerAnalytics1: any;
  customerAnalytics2: any;
  StudentAndTeacher: any;
  CourseSubject: any;
  CommentRating: any;
  Certificate: any;
  dateCertificate: any;
  TestExam: any;
  dataExam: any = '';
  dataSubJ: any;
  datatestLesson: any;
  dataPostTests: any;
  localRole: any;
  showLoading: boolean = true;
  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
    this.chartDB = ChartDB;
  }

  ngOnInit() {
    var localLogin = localStorage.getItem("currentUserLogin");
    if (localLogin != null) {
      var dataLocalSelectPortal = JSON.parse(localLogin);
      this.localRole = dataLocalSelectPortal.role;
    }

    this.getStudent();
    this.getStudentAndTeacher();
    this.onChangSelectPortal();
  }

  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.getStudent();
        this.getStudentAndTeacher();
      }
    });
  }

  getStudent() {
    var getSuccess: any;
    var data: any = [];
    this.adminService.fetchDataAllMemmerUser().then((response) => {
      getSuccess = response;
      for (let i = 0; i < getSuccess.dataStudents.length; i++) {
        data.push({
          value: getSuccess.dataStudents[i].dataStudents.stdntId,
          label: getSuccess.dataStudents[i].dataStudents.fullNameTh,
        });
      }
      this.dataStudentAll = data;

      this.selectedStudent = this.dataStudentAll.length > 0 ? this.dataStudentAll[0].value : "";
      this.getDetailStudent(this.selectedStudent);
      this.getDataSubjectStudent(this.selectedStudent);
    });
  }

  getDataSubjectStudent(idstd: any) {
    this.dataLoading1 = true;
    this.dataLoading2 = true;
    this.dataLoading3 = true;

    this.getCourseList(idstd);
    this.getDetailStudent(idstd);
    // this.getStudentCertificate(idstd);

    this.getDataCourses(idstd);
    this.getStudentCertificate(idstd);
    this.getCourseSubject(idstd);
    this.getCommentRating(idstd);
    this.ReportTestExam(idstd);
  }

  getDetailStudent(studentId: any) {
    var detailStd: any;
    this.adminService.fetchDataAllMemmerUser().then((res) => {
      detailStd = res;
      this.detailStudent = detailStd.dataStudents.find((s) => s.dataStudents.stdntId == studentId);
      if (this.detailStudent != undefined) {
        this.address = this.detailStudent.dataStudents.address.split(',');
      } else {
        this.address = [];
      }
    });
  }

  getDataCourses(studentId: any) {
    var array = [];
    var array2 = [];
    var checklearnStudent: boolean = false;
    this.studentBuyCouses = [];
    this.studentBuyCouses2 = [];
    var setStudentBuyCouses = [];
    var learningWorst: number = 0;
    this.adminService.FindSudentBuyCoursesList(studentId).then((res) => {
      if (res.status == true) {
        if (res.status) {
          setStudentBuyCouses = res.studentBuyCouses;

          for (let i = 0; i < res.studentBuyCouses.length; i++) {
            checklearnStudent = false;
            learningWorst = 0;
            for (
              let j = 0;
              j < res.studentBuyCouses[i].dataLearns.length;
              j++
            ) {
              if (res.studentBuyCouses[i].dataLearns[j].learnStatus) {
                checklearnStudent = true;
                learningWorst++;
              } else {
                checklearnStudent = false;
              }
            }
            setStudentBuyCouses[i]['studentlearnSuccess'] = checklearnStudent;
            setStudentBuyCouses[i]['studentlearnWorst'] =
              res.studentBuyCouses[i].dataLearns.length > 0
                ? (learningWorst * 100) /
                res.studentBuyCouses[i].dataLearns.length
                : 0;
          }
        } else {
          this.studentBuyCouses = null;
          this.studentBuyCouses2 = null;
        }
        for (let index = 0; index < setStudentBuyCouses.length; index++) {
          if (setStudentBuyCouses[index].studentlearnSuccess) {
            array.push(setStudentBuyCouses[index]);
          } else {
            array2.push(setStudentBuyCouses[index]);
          }

          if (index == setStudentBuyCouses.length - 1) {
            this.studentBuyCouses = array;
            this.studentBuyCouses2 = array2;
          }
        }
        this.dataLoading1 = false;
      } else {
        this.studentBuyCouses = null;
        this.studentBuyCouses2 = null;
        this.dataLoading1 = false;
      }
    });
  }

  getCourseList(studentId) {
    this.adminService.FindCourseStudentList().then((res) => {
      var dataFind = res.dataCoursesStudent.find((s) => s.stdntId == studentId);
      if (dataFind != undefined && res.status == true) {
        this.dataSubject = dataFind.dataSubject;
      } else {
        this.dataSubject = null;
      }
      this.dataLoading1 = false;
    });
  }

  getStudentAndTeacher() {
    var StdAndTeacher: any;
    this.adminService.FindStudentAndTeacherAllUse().then((res) => {
      StdAndTeacher = res;
      if (res.status == true) {
        this.StudentAndTeacher = StdAndTeacher.data;
        this.customerAnalytics1 = {
          chart: {
            height: 150,
            type: 'donut',
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
              },
            },
          },
          labels: ['ผู้ใช้ใหม่', 'ผู้ใช้เก่า'],
          series: [
            this.StudentAndTeacher.student.newStudents,
            this.StudentAndTeacher.student.oldStudents,
          ],
          legend: {
            show: false,
          },
          tooltip: {
            theme: 'datk',
          },
          grid: {
            padding: {
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          colors: ['#4680ff', '#2ed8b6'],
          fill: {
            opacity: [1, 1],
          },
          stroke: {
            width: 0,
          },
        };
        this.customerAnalytics2 = {
          chart: {
            height: 150,
            type: 'donut',
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                size: '75%',
              },
            },
          },
          labels: ['ผู้ใช้ใหม่', 'ผู้ใช้เก่า'],
          series: [
            this.StudentAndTeacher.teacher.newTeachers,
            this.StudentAndTeacher.teacher.oldTeachers,
          ],
          legend: {
            show: false,
          },
          tooltip: {
            theme: 'dark',
          },
          grid: {
            padding: {
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          colors: ['#ff5370', '#2ed8b6'],
          fill: {
            opacity: [1, 1],
          },
          stroke: {
            width: 0,
          },
        };
      }
    });
  }

  getStudentCertificate(studentId: any) {
    var StdCertificate: any;
    this.adminService.findStudentCertificate(studentId).then(
      (res) => {
        StdCertificate = res;
        this.Certificate = StdCertificate.dataCertificate;
      },
      (err) => {
        console.log('err : ', err);
      }
    );
  }

  getCourseSubject(studentId: any) {
    var coursesubject: any;
    this.adminService.FindCourseSubjectByStudent(studentId).then((res) => {
      coursesubject = res;
      if (coursesubject.status == true) {
        this.CourseSubject = coursesubject;
        if (this.CourseSubject.length != 0) {
          if (this.CourseSubject.data != undefined) {
            this.dataExam =
              this.CourseSubject.data[0].dataBuyCourse.dataSubject.subjectId;
          }
        }
        // setTimeout(() => {
        this.dataLoading3 = false;
        // }, 1000);
      } else {
        this.CourseSubject = null;
        this.dataLoading3 = false;
      }
    });
  }

  getCommentRating(studentId: any) {
    var commentrating: any;
    var dataCommentRating: any = [];
    this.adminService.FindStudentCommentRating(studentId).then((res) => {
      commentrating = res;

      for (let i = 0; i < commentrating.data.length; i++) {
        if (commentrating.data[i].dataComments.length != 0) {
          dataCommentRating.push(commentrating.data[i]);
        }
      }
      this.CommentRating = dataCommentRating;
    });
  }

  ReportTestExam(studentId: any) {
    var testexam: any;
    this.adminService.FindStudentTest(studentId).then((res) => {
      testexam = res;
      this.TestExam = testexam.data;
      if (this.TestExam != undefined) {
        this.checkExam(this.dataExam);
      } else {
        this.datatestLesson == null;
      }
    });
  }

  checkExam(idSub: any) {
    this.showLoading = true;
    this.dataExam = idSub;
    var lesson: number;
    var postTests: number;
    this.dataSubJ = this.TestExam.find((s) => s.subjectId == idSub);

    if (this.dataSubJ.dataLesson.length != 0) {
      lesson = this.dataSubJ.dataLesson.length - 1;
      this.datatestLesson = this.dataSubJ.dataLesson[lesson];
    } else {
      lesson = 0;
      this.datatestLesson = null;
    }

    if (this.dataSubJ.dataPostTests.length != 0) {
      postTests = this.dataSubJ.dataPostTests.length - 1;
      this.dataPostTests = this.dataSubJ.dataPostTests[postTests];
    } else {
      postTests = 0;
      this.dataPostTests = null;
    }
    this.showLoading = false;
  }

  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
}
